// imports
import React, {ReactNode} from "react";
import ReactDOM from "react-dom";

// export function
// get parent and className props as well as the children
export default function Modal({ children, parent, className, onBgClick }: {
  children?: ReactNode,
  parent?: HTMLElement | null,
  className?: string,
  onBgClick?: ()=>void,
}) {
  // Create div to contain everything
  const el = React.useMemo(() => document.createElement("div"), []);
  // On mount function
  React.useEffect(() => {
    // work out target in the DOM based on parent prop
    const target = parent && parent.appendChild ? parent : document.querySelector('#app') as HTMLDivElement;
    // Default classes
    const classList = ["modal-container"];
    // If className prop is present add each class the classList
    if (className) className.split(" ").forEach((item) => classList.push(item));
    classList.forEach((item) => el.classList.add(item));
    // Append element to dom
    target.appendChild(el);
    onBgClick && el.addEventListener("click", () => {
      console.log(124);
      onBgClick();
    });
    // On unmount function
    return () => {
      // Remove element from dom
      target.removeChild(el);
    };
  }, [el, parent, className, onBgClick]);
  // return the createPortal function
  return ReactDOM.createPortal(children, el);
}
