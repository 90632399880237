import React, { useContext, useEffect, useState } from 'react';
import { getHeader, LOGOUT_URL } from '../../utils/api';
import ModelHeader from '../../utils/models/joined/Header';
import { AuthContext } from '../../utils/providers/AuthProvider';
import ModalAboutUs from './ModalAboutUs';
import ModalReel from './ModalReel';
import "./ModalSettingsV4.scss";
import ModalTechnicalSupport from './ModalTechnicalSupport';

interface ModalSettingsV4Props {
  onClose?: () => void,
}

const ModalSettingsV4 = ({ onClose }: ModalSettingsV4Props) => {
  const { signOut } = useContext(AuthContext);
  // const svgBar = useRef<HTMLDivElement | null>(null);
  const [modalAboutUs, setModalAboutUs] = useState(false);
  const [modalTechnicalSupport, setModalTechnicalSupport] = useState(false);
  // const [volumePercent, setVolumePercent] = useState(0);
  const [userInfo, setUserInfo] = useState<ModelHeader | null>(null);

  // const getCurrentPercent = () => {
  //   console.log(volumePercent);
  //   return volumePercent / 100;
  // }
  //
  // const moveScrollbar = (v: number) => {
  //   setVolumePercent(v * 100);
  // }
  //
  // const volumeUp = () => {
  //   let v = (getCurrentPercent() + .05);
  //   v = v >= 1 ? 1 : v;
  //   // console.log(v);｛
  //   putUserSettings('volume', v).then(() => {
  //     (document.querySelector('#bgAudio') as HTMLAudioElement).volume = v;
  //   });
  //   moveScrollbar(v);
  // }
  //
  // const volumeDown = () => {
  //   let v = (getCurrentPercent() - .05);
  //   v = v <= 0 ? 0 : v;
  //   // console.log(v);
  //   putUserSettings('volume', v).then(() => {
  //     (document.querySelector('#bgAudio') as HTMLAudioElement).volume = v;
  //   });
  //   moveScrollbar(v);
  // }

  const doLogout = () => {
    signOut(() => {
      //authSignOut().then(() => {
      window.location.href = `${LOGOUT_URL}?finish_uri=${encodeURIComponent(window.location.href)}`;
      //});
    })
  }
  // const svgDebugger: HTMLDivElement = document.querySelector('#svgDebugger') as HTMLDivElement;
  // const downEvent = (e: { alias: string, width: number, target: HTMLDivElement, offsetX: number, offsetY: number }) => {
  //   console.log(e);
  //   // let width = (e.target.parentElement as HTMLDivElement).offsetWidth;
  //   let offsetX = e.offsetX - (e.width / 46 * 3.5);
  //   let newVolume = offsetX / (e.width / 46 * 40);
  //   newVolume = Math.min(1, Math.max(0, newVolume));
  //
  //   putUserSettings('volume', newVolume).then(() => {
  //     (document.querySelector('#bgAudio') as HTMLAudioElement).volume = newVolume;
  //   });
  //   // setVolumePercent(Math.round(newVolume * 100));
  //
  //   console.log({
  //     // pageX: e.pageX,
  //     // clientX: e.clientX,
  //     // rect: (e.target as HTMLDivElement).getBoundingClientRect(),
  //     alias: e.alias,
  //     width: e.width,
  //     offsetX,
  //     newVolume,
  //     offsetXRaw: e.offsetX,
  //     offsetXMinus: e.offsetX - 3.5,
  //     offsetXPercent: Math.round((e.offsetX - 3.5) / 40 * 100),
  //     // screenX: e.screenX,
  //     // movementX: e.movementX,
  //   });
  //   if (svgBar && svgBar.current) {
  //     (svgBar.current.children[0] as HTMLDivElement).style.transform = `translateX(${e.offsetX}px)`;
  //   }
  // }
  // let isMouseDown = false;
  // const mouseleave = () => isMouseDown = false
  // const mousedown = () => isMouseDown = true
  // const mousemove = (e: MouseEvent) => {
  //   // console.log(isMouseDown);
  //   if (isMouseDown) {
  //     downEvent({
  //       alias: 'mousemove',
  //       target: e.target as HTMLDivElement,
  //       width: ((e.target as HTMLDivElement).parentElement as HTMLDivElement).offsetWidth,
  //       offsetX: e.offsetX,
  //       offsetY: e.offsetY,
  //     });
  //     e.preventDefault();
  //     e.stopPropagation();
  //   }
  // }
  // const mouseup = (e: MouseEvent) => {
  //   isMouseDown = false;
  //   e.preventDefault();
  //   e.stopPropagation();
  //   downEvent({
  //     alias: 'mouseup',
  //     target: e.target as HTMLDivElement,
  //     width: ((e.target as HTMLDivElement).parentElement as HTMLDivElement).offsetWidth,
  //     offsetX: e.offsetX,
  //     offsetY: e.offsetY,
  //   });
  // };
  //
  // let scale = 1;
  // if ((window.innerWidth / window.innerHeight) > (1920 / 1080)) {
  //   scale = window.innerHeight / 1080;
  // } else {
  //   scale = window.innerWidth / 1920;
  // }
  // const touchmove = (e: TouchEvent) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   // console.log((e.target as HTMLDivElement).getBoundingClientRect().width, scale);
  //   downEvent({
  //     alias: 'touchmove',
  //     target: e.target as HTMLDivElement,
  //     // width: (e.target as HTMLDivElement).getBoundingClientRect().width / scale,
  //     width: ((e.target as HTMLDivElement).parentElement as HTMLDivElement).offsetWidth * scale,
  //     offsetX: e.touches[0].pageX - (e.target as HTMLDivElement).getBoundingClientRect().left,
  //     offsetY: e.touches[0].pageY - (e.target as HTMLDivElement).getBoundingClientRect().top,
  //   })
  // };

  // const refSvgBar = (e: HTMLDivElement | null) => {
  //   if (svgBar.current) {
  //     svgBar.current.removeEventListener('mouseup', mouseup);
  //     svgBar.current.removeEventListener('mousemove', mousemove);
  //     svgBar.current.removeEventListener('mousedown', mousedown);
  //     svgBar.current.removeEventListener('mouseleave', mouseleave);
  //     svgBar.current.removeEventListener('touchstart', touchmove);
  //     svgBar.current.removeEventListener('touchmove', touchmove);
  //   }
  //
  //   if (e) {
  //     svgBar.current = e;
  //     svgBar.current.addEventListener('mouseup', mouseup);
  //     svgBar.current.addEventListener('mousemove', mousemove);
  //     svgBar.current.addEventListener('mousedown', mousedown);
  //     svgBar.current.addEventListener('mouseleave', mouseleave);
  //     svgBar.current.addEventListener('touchstart', touchmove);
  //     svgBar.current.addEventListener('touchmove', touchmove);
  //   }
  // }

  useEffect(() => {
    getHeader().then(x => {
      setUserInfo(x);
      // setVolumePercent(x.userSettings.volume);
    });
  }, []);

  if (null === userInfo) {
    return <></>;
  }
  return (
    <ModalReel className="modal-settings" open={true} onClose={onClose}>
      <div className="modal-settings-body">
        <div className="modal-settings-body-volume">
          {/* <button onClick={volumeDown} className="modal-settings-body-volume-minus"></button>
          <div className="modal-settings-body-volume-value">{volumePercent}</div>
          <div ref={refSvgBar} className="modal-settings-body-volume-bar">
            <div className="modal-settings-body-volume-bar-handle"></div>
          </div>
          <button onClick={volumeUp} className="modal-settings-body-volume-plus"></button> */}
        </div>
        <div className="modal-settings-body-buttons">
          <button onClick={() => setModalTechnicalSupport(true)} aria-label="技術支援">技術支援</button>
          <button onClick={() => setModalAboutUs(true)} aria-label="關於本站">關於本站</button>
          <button onClick={() => doLogout()} aria-label="登出">登出</button>
        </div>
        {modalAboutUs ? <ModalAboutUs onClose={() => setModalAboutUs(false)} /> : null}
        {modalTechnicalSupport ? <ModalTechnicalSupport onClose={() => setModalTechnicalSupport(false)} /> : null}
      </div>
      <div className="modal-settings-dingding-1"></div>
      <div className="modal-title">
        <div className="modal-title-l"></div>
        <div className="modal-title-c">設定</div>
        <div className="modal-title-r"></div>
      </div>
    </ModalReel>)
}

export default ModalSettingsV4;
