import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ScrollbarVertical from '../../components/project-ui/ScrollbarVertical';
import { postEnquiry } from '../../utils/api';
import ModalReel from './ModalReel';
import "./ModalTechnicalSupport.scss";

interface ModalTechnicalSupportProps {
  onClose?: () => void,
}

const ModalTechnicalSupport = ({ onClose }: ModalTechnicalSupportProps) => {
  const [categoryShow, setCategoryShow] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { register, handleSubmit, setValue } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
    setButtonDisabled(true);
    if (data.email && data.category && data.details) {
      // console.log(data);
      postEnquiry(data).then((e) => {
        // console.log(e);
        setButtonDisabled(false);
        setValue('email', '');
        setValue('category', '');
        setValue('details', '');

        alert('信息發送成功');
      });
    } else {
      setButtonDisabled(false);
      alert('請輪入全部資料');
    }
  };

  const onCategoryClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setCategoryShow(o => !o);
  }

  const onCategorySelect = (v: string) => {
    setValue('category', v);
    setCategoryShow(false);
  }

  return (
    <ModalReel className="modal-technical_support" open={true} onClose={onClose} theme="blue">
      <div className="modal-technical_support-body">
        <ScrollbarVertical>
          <form onSubmit={handleSubmit(onSubmit)} aira-label="技術支援表單">
            <div className="form">
              <div className="input-group">
                <div className="input-label"><label aria-label="聯絡電郵">聯絡電郵：</label></div>
                <div className="input-field">
                  <input {...register("email")} placeholder="聯絡電郵" aria-label="聯絡電郵" />
                </div>
              </div>
              <div className="input-group">
                <div className="input-label"><label aria-label="技術支援類別">技術支援類別：</label></div>
                <div className="input-field input-field-select">
                  <input onClick={onCategoryClick} {...register("category")} placeholder="技術支援類別" aria-label="技術支援類別" />
                  {/* <button type="button" onClick={onCategoryClick}>
                    Select
                  {/* <SvgButton prefix="/assets/settings/technical-support/" src1="setting-support-button-arrow.svg" src2="setting-support-button-arrow-select.svg" alt="回到主頁"></SvgButton> */}
                  {/* </button> */}
                  <div className={`input-field-select-options${categoryShow ? ' input-field-select-options-on' : ''}`}>
                    <div onClick={() => onCategorySelect('畫面異常')} className="input-field-select-option"><span>畫面異常</span></div>
                    <div onClick={() => onCategorySelect('計分紀錄異常')} className="input-field-select-option"><span>計分紀錄異常</span></div>
                    <div onClick={() => onCategorySelect('其他')} className="input-field-select-option"><span>其他</span></div>
                  </div>
                </div>
              </div>
              <div className="input-group">
                <div className="input-label"><label aria-label="詳細內容">詳細內容：</label></div>
                <div className="input-field input-field-textarea">
                  <textarea {...register("details")} placeholder="詳細內容" aria-label="詳細內容"></textarea>
                </div>
              </div>

              <div className="button-area">
                <button className="page-settings-technical-support-submit" type="submit" aria-label="提交" disabled={buttonDisabled}>提交</button>
              </div>
            </div>
          </form>
        </ScrollbarVertical>
      </div>
      {/* <div className="modal-settings-dingding-1"></div> */}
      <div className="modal-title">
        <div className="modal-title-l"></div>
        <div className="modal-title-c">技術支援</div>
        <div className="modal-title-r"></div>
      </div>
    </ModalReel>)
}

export default ModalTechnicalSupport;
