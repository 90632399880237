import { memo, useEffect, useState } from 'react';
import ScrollbarVertical from '../../components/project-ui/ScrollbarVertical';
import SvgButton from '../../components/ui/SvgButton';
import { getAboutUs } from '../../utils/api';
import './about-us.scss';

interface PageSettingsAboutUsProps {
  onClose: () => void
}

function PageSettingsAboutUs(props: PageSettingsAboutUsProps) {
  const [aboutUs, setAboutUs] = useState({ content: '' });

  useEffect(() => {
    getAboutUs().then(c => setAboutUs(c));
  }, []);

  if ('' === aboutUs.content) {
    return <></>;
  }

  return (
    <div className="page-settings-about-us dialog" style={{ backgroundImage: "url(/assets/settings/about-us/setting-about-bubble.svg)" }}>
      <h3 className="dialog-title">關於本站</h3>
      <button className="dialog-btn-close" aria-label="關閉" onClick={() => props.onClose()}><SvgButton prefix="/assets/settings/" src1="setting-button-close.svg" src2="setting-button-close-select.svg" alt="關閉"></SvgButton></button>
      <div className="dialog-content">
        <ScrollbarVertical>
          <div dangerouslySetInnerHTML={{ __html: aboutUs.content }}></div>
        </ScrollbarVertical>
      </div>
    </div>
  )
}

export default memo(PageSettingsAboutUs);
