import React, { createContext, ReactNode, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { useAppConfigContext } from './AppConfigProvider';
import { DevPortal } from './DevPortalProvider';


type Action = 'start' | 'resume' | 'stop' | 'forceStop' | 'forceStart'
  | { action: 'setSrc', src: Filename }
  | { action: 'setAudio', audio: HTMLAudioElement }

interface State {
  src: null | Filename,
  audio: null | HTMLAudioElement,
  playing: boolean,
  stopped: boolean,
}

export const initialState: State = {
  src: null,
  audio: null,
  playing: false,
  stopped: false,
};

export const reducer = (state: State, action: Action): State => {
  // console.log('[BgmProvider] reducer', state, action);
  switch (action) {
    case "start":
      !state.stopped && state.audio && state.audio.play().then(() => { });
      return { ...state, playing: true };
    case "resume":
      !state.stopped && state.audio && state.audio.play().then(() => { });
      return { ...state, playing: true };
    case "stop":
      state.audio && state.audio.pause()
      return { ...state, playing: false };
    case 'forceStop':
      state.audio && state.audio.pause()
      return { ...state, playing: false, stopped: true };
    case 'forceStart':
      state.audio && state.audio.play().then(() => { });
      return { ...state, playing: true, stopped: false };
    default:
      switch (action.action) {
        case 'setSrc':
        case 'setAudio':
          return { ...state, ...action };
        default:
          return state;
      }
  }
}

const Filenames = ["bg1", "bg2", "bg3", "bg4"] as const;
type Filename = typeof Filenames[number];

interface Props {
  children: ReactNode,
}

interface Context {
  loaded: boolean,
  tryStopBgMusic: () => void,
  tryResumeBgMusic: () => void,
  tryStartBgMusic: () => void,
  //
  forceStop: () => void,
  forceStart: () => void,
  //
  setSrc: (src: Filename) => void,
  //
  state: State,
}

const DEFAULTCONTEXT: Context = {
  loaded: false,
  tryStopBgMusic: () => { },
  tryResumeBgMusic: () => { },
  tryStartBgMusic: () => { },
  //
  forceStop: () => { },
  forceStart: () => { },
  //
  setSrc: (_src: Filename) => { },
  //
  state: initialState,
};

export const BgmContext = createContext<Context>(DEFAULTCONTEXT);

export const useBgmContext = () => useContext(BgmContext);

export default function BgmProvider(props: Props) {
  const [value, setValue] = useState<Context>(DEFAULTCONTEXT);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { dateTimestamp } = useAppConfigContext();

  const bgAudio = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    // console.log('[BgmProvider] useEffect', value.loaded, bgAudio.current);
    if (false === value.loaded && bgAudio.current) {
      bgAudio.current.volume = .05;
      // console.log('[BgmProvider] useEffect', 'in');
      dispatch({ action: 'setAudio', audio: bgAudio.current })
      dispatch({ action: 'setSrc', src: Filenames[Math.floor(Math.random() * Filenames.length)] })
      const startByEvent = () => {
        // console.log('[BgmProvider] startByEvent');
        dispatch('start')
      };
      document.addEventListener('touchstart', startByEvent);
      document.addEventListener('mousedown', startByEvent);
      document.addEventListener('click', startByEvent);
      document.addEventListener('keydown', startByEvent);

      const stopByEvent = () => {
        // console.log('[BgmProvider] stopByEvent');
        dispatch('stop')
      };
      const resumeByEvent = () => {
        // console.log('[BgmProvider] resumeByEvent');
        dispatch('resume')
      };
      window.addEventListener("focus", resumeByEvent);
      window.addEventListener("blur", stopByEvent);

      setValue(o => ({
        ...o,
        loaded: true,
        tryStopBgMusic: stopByEvent,
        tryResumeBgMusic: resumeByEvent,
        tryStartBgMusic: startByEvent,
        //
        forceStop: () => {
          // console.log('[BgmProvider] forceStop');
          dispatch('forceStop')
        },
        forceStart: () => {
          // console.log('[BgmProvider] forceStart');
          dispatch('forceStart')
        },
        //
        setSrc: (src: Filename) => dispatch({ action: 'setSrc', src }),
      }))

      return () => {
        document.removeEventListener('touchstart', startByEvent);
        document.removeEventListener('mousedown', startByEvent);
        document.removeEventListener('click', startByEvent);
        document.removeEventListener('keydown', startByEvent);

        window.removeEventListener("focus", resumeByEvent);
        window.removeEventListener("blur", stopByEvent);
      }
    } else {
      setValue(o => ({
        ...o,
        loaded: true,
        tryStopBgMusic: () => { },
        tryResumeBgMusic: () => { },
        tryStartBgMusic: () => { },
        //
        forceStop: () => { },
        forceStart: () => { },
        //
        setSrc: () => { },
      }))
    }
  }, [bgAudio, value.loaded]);

  if (false === value.loaded) {
    return <></>;
  }

  ('development' === process.env.NODE_ENV) && console.log('[b4-return] BgmProvider');
  return (
    <BgmContext.Provider value={{ ...value, state }}>
      <audio ref={bgAudio} controls id="bgAudio" src={`/assets/ui/bgm/${state.src}.mp3?d=${dateTimestamp}`} preload='auto' loop hidden />
      {props.children}
      <DevPortal>
        {Filenames.map(f => <button key={f} onClick={() => value.setSrc(f)}>{f}</button>)}
      </DevPortal>
    </BgmContext.Provider>
  )
}
