import preval from 'preval.macro';
import React, { lazy, ReactNode, Suspense, useContext } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import 'swiper/components/navigation/navigation.scss';
import SwiperCore, { Controller, Keyboard, Navigation } from 'swiper/core';
import 'swiper/swiper.scss';
import './App.scss';
import Background from './components/icons/Background';
import Header from './components/project-ui/Header';
import Maintenance from './components/project-ui/modals/Maintenance';
import Window from './components/ui/Window';
import CompatUrlRedirect from './pages/compat-url-redirect';
import AppConfigProvider from './utils/providers/AppConfigProvider';
import { AuthContext } from './utils/providers/AuthProvider';
import { DevPortalDiv } from './utils/providers/DevPortalProvider';

const dateTimestamp = preval`module.exports = new Date().toISOString();`

const PageHistory = lazy(() => import('./pages/history'));
const PageArena = lazy(() => import('./pages/arena'));
const PageIsland = lazy(() => import('./pages/island'));
// const PageLogin = lazy(() => import('./pages/login'));
// const PageMain = lazy(() => import('./pages/main'));
// const PageTreasure = lazy(() => import('./pages/treasure'));
// const PageLearningResources = lazy(() => import('./pagesV4/learning_resources'));
// const PageReadingHistory = lazy(() => import('./pagesV4/reading_history'));

const PageLoginV4 = lazy(() => import('./pagesV4/login'));
const PageMainV4 = lazy(() => import('./pagesV4/main'));
const PageReadingHistoryV4 = lazy(() => import('./pagesV4/reading_history'));
const PageLearningResourcesV4 = lazy(() => import('./pagesV4/learning_resources'));
const PageTreasureV4 = lazy(() => import('./pagesV4/treasure'));

SwiperCore.use([Navigation, Controller, Keyboard]);

function CmsRoute({ render, ...rest }: { render: (props: any) => any, [k: string]: any }) {
  const isAuthenticated = true;
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (render(props)) : <h2>401</h2>
      }
    />
  )
}

function PrivateRoute({ children, ...rest }: { children: ReactNode, [k: string]: any }) {
  const { isAuthenticated, loaded } = useContext(AuthContext);
  if (!loaded) {
    return <></>;
  }
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (children) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

function InnerAppSwitch() {
  let location = useLocation();
  return (
    <TransitionGroup className="tg-page">
      <CSSTransition key={location.key} classNames="fade" timeout={300} >
        <Switch location={location}>
          {/*  */}
          <Route exact path="/login-v4"><PageLoginV4 /></Route>
          <Route exact path="/login"><PageLoginV4 /></Route>
          {/*  */}
          <PrivateRoute exact path="/main-v4"><PageMainV4 /></PrivateRoute>
          <PrivateRoute exact path="/reading-v4"><PageReadingHistoryV4 /></PrivateRoute>
          <PrivateRoute exact path="/resources-v4"><PageLearningResourcesV4 /></PrivateRoute>
          <PrivateRoute exact path="/teacher-v4"><PageLearningResourcesV4 isTeacher={true} /></PrivateRoute>
          <PrivateRoute path="/treasure-v4/:mode?"><PageTreasureV4 /></PrivateRoute>
          {/*  */}
          <PrivateRoute exact path="/history"><PageHistory></PageHistory></PrivateRoute>
          <PrivateRoute exact path="/arena"><PageArena></PageArena></PrivateRoute>
          {/* <PrivateRoute path="/arena/:mode"><PageArena></PageArena></PrivateRoute> */}
          <PrivateRoute path="/arena/:mode/:index?"><PageArena></PageArena></PrivateRoute>
          {/* <PrivateRoute exact path="/leaderboard"><PageLeaderboard></PageLeaderboard></PrivateRoute> */}
          {/* <PrivateRoute path="/settings"><PageSettings></PageSettings></PrivateRoute> */}
          <PrivateRoute path="/island/:index/:state/" children={<PageIsland />} />
          {/* <PrivateRoute path="/learning_resources/:mode?" children={<PageLearningResources />} /> */}
          {/* <PrivateRoute path="/reading_history/:mode?" children={<PageReadingHistory />} /> */}
          {/* <PrivateRoute path="/treasure/:mode?" children={<PageTreasure />} /> */}
          <PrivateRoute path="/subui.php" children={<CompatUrlRedirect />} />
          <PrivateRoute path="/main.php" children={<CompatUrlRedirect />} />

          <CmsRoute path="/__cms/qna/preview/:index/:id" render={(p: any) => <PageIsland index={p.match.params.index} state={'qna'} previewId={`${p.match.params.id}`} />} />
          <CmsRoute path="/__cms/arena/:mode/preview/:index/:id" render={(p: any) => <PageArena previewIndex={p.match.params.index} previewId={`${p.match.params.id}`} />} />

          {/* <PrivateRoute path="*"><PageMain></PageMain></PrivateRoute> */}
          <PrivateRoute path="*"><PageMainV4></PageMainV4></PrivateRoute>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

function InnerApp() {
  // let location = useLocation();
  // const { units } = useContext(UnitContext);

  return (
    <>
      {/* <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          {('/login' === location.pathname ? <></> : <Header />)}
        </CSSTransition>
      </TransitionGroup> */}
      <Header />
      <InnerAppSwitch />
    </>
  );
}

function App() {

  console.log('Build date: ', dateTimestamp, ' @ ', process.env.NODE_ENV);

  return (
    <Router>
      <AppConfigProvider dateTimestamp={dateTimestamp}>
        <Maintenance />

        <div id="app" className="App resizable-text-normal" style={{}}>
          <Background></Background>

          <Suspense fallback={<div></div>}>
            <Switch>
              <Route path="*">
                <InnerApp />
              </Route>
            </Switch>
          </Suspense>
        </div>

        {/* <Cursor /> */}
        <Window />
        {/* <MyDatGui /> */}

        <audio controls id="sound-click" src="/assets/ui/mouse/MouseClick1.mp3" preload='auto' hidden />

        <DevPortalDiv />
      </AppConfigProvider>
    </Router>
  )
}

export default App;
