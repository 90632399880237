import { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Modal from '../../components/ui/Modal';
import SvgButton from '../../components/ui/SvgButton';
import { ArenaContext } from '../../utils/providers/ArenaProvider';
import PageArenaTitle from './arena-title';

interface PageArenaStageResultProps {
  index: string,
  onClick: () => void,
}

export default function PageArenaStageResult(props: PageArenaStageResultProps) {
  const [loaded, setLoaded] = useState(false);
  const { value } = useContext(ArenaContext);

  useEffect(() => {
    setLoaded(true);
  }, [value]);

  if (!loaded) {
    return <></>;
  }

  const index = parseInt(props.index);

  return (
    <Modal>
      <div className={`page-island-qna-score page-island-arena-score page-island-qna-stage-result page-arena-${index} dialog`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/arena/battle-score-popup.svg)` }}>
        <Link className="dialog-btn-close" aria-label="關閉" to={`/arena/index`}><SvgButton prefix="/assets/settings/" src1="setting-button-close.svg" src2="setting-button-close-select.svg" alt="關閉"></SvgButton></Link>
        <div className="dialog-content">
          <div className="page-island-qna-stage-result-content">
            <PageArenaTitle index={props.index} />

            <span className="page-island-qna-stage-result-content-unit-topest">{value.unitTopest[index] ?? 0} / 30 題</span>

            <span className="page-island-qna-stage-result-content-correct-questions">{value.correctQuestions[index] ?? 0} / {value.questionCountByUnit[index]} 題</span>

            <span className="page-island-qna-stage-result-content-count-unit">{value.countUnit[index] ?? 0}</span>
          </div>
          <button onClick={props.onClick}>
            <SvgButton prefix="/assets/arena/" src1="battle-button-enter.svg" src2="battle-button-enter-select.svg" alt="確定" />
          </button>
        </div>
      </div>
    </Modal>
  )
}
