import React, { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import { getUnits } from '../api';
import Unit from '../models/Unit';
import { AuthContext } from './AuthProvider';

interface Props {
  children: ReactNode,
}

interface Context {
  loaded: boolean,
  units: Array<Unit>,
  lastUpdated: number,
}

const DEFAULTCONTEXT: Context = {
  loaded: false,
  units: [],
  lastUpdated: 0,
};

export const UnitContext = createContext<Context>(DEFAULTCONTEXT);

export const useUnitContext = () => useContext(UnitContext);

export default function UnitProvider(props: Props) {
  const [value, setValue] = useState<Context>(DEFAULTCONTEXT);
  const { lastUpdated } = useContext(AuthContext);

  // const [units, setUnits] = useState<Array<Unit>>([]);

  useEffect(() => {
    // console.log('UnitProvider', 'useEffect')
    if (lastUpdated > value.lastUpdated) {
      // console.log('UnitProvider', 'useEffect get' , lastUpdated > value.lastUpdated, lastUpdated , value.lastUpdated)
      getUnits().then((units) => {
        // console.log('UnitProvider', 'useEffect getUnits', 'success');
        setValue(_o => ({ loaded: true, units, lastUpdated: Date.now() }));
      }).catch(() => {
        // console.log('UnitProvider', 'useEffect getUnits', 'false');
        setValue(_o => ({ loaded: true, units: [], lastUpdated: Date.now() }));
      });
    }
  }, [lastUpdated, value.lastUpdated]);

  if (! value.loaded) {
    return null;
  }

  console.log('[b4-return] UnitProvider');
  return (
    <UnitContext.Provider value={value}>
      {props.children}
    </UnitContext.Provider>
  )
}
