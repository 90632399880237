import React, { PropsWithChildren } from "react";
import Modal from "../../components/ui/Modal";
import "./ModalReel.scss";

export type ModalReelTheme = "blue" | "green" | "inner-gold";
export type ModalReelSize = "sm" | "md";

interface ModalReelProps {
  className?: string,
  open?: boolean,
  onClose?: () => void,
  theme?: ModalReelTheme,
  size?: ModalReelSize,
}

const ModalReel = ({ open, onClose, className, children, theme, size }: PropsWithChildren<ModalReelProps>) => {

  if (!open) {
    return null;
  }

  return (
    <Modal>
      <div className={`modal-reel ${theme ? `modal-reel-${theme}` : ""} ${size ? `modal-reel-${size}` : ""} ${className}`}>
        <div className="modal-reel-start"></div>
        <div className="modal-reel-main">
          <div className="modal-reel-t">
            <div className="modal-reel-tl"></div>
            <div className="modal-reel-tc"></div>
            <div className="modal-reel-tr"></div>
          </div>
          <div className="modal-reel-c">
            <div className="modal-reel-cl"></div>
            <div className="modal-reel-cc">
              {children}

              <button className="modal-reel-close" onClick={onClose}></button>
            </div>
            <div className="modal-reel-cr"></div>
          </div>
          <div className="modal-reel-b">
            <div className="modal-reel-bl"></div>
            <div className="modal-reel-bc"></div>
            <div className="modal-reel-br"></div>
          </div>
        </div>
        <div className="modal-reel-end"></div>
      </div>
    </Modal>
  )
}

export default ModalReel;
