import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { getNews } from '../api';
import News from '../models/News';
import { AuthContext } from './AuthProvider';

interface Props {
  children: ReactNode,
}

interface Context {
  loaded: boolean,
  news: Array<News>,
  lastUpdated: number,
}

const DEFAULTCONTEXT: Context = {
  loaded: false,
  news: [],
  lastUpdated: 0,
};

export const NewsContext = createContext<Context>(DEFAULTCONTEXT);

export const useNewsContext = () => useContext(NewsContext);

export default function NewsProvider(props: Props) {
  const [value, setValue] = useState<Context>(DEFAULTCONTEXT);
  const { logged, lastUpdated } = useContext(AuthContext);

  useEffect(() => {
    if (lastUpdated > value.lastUpdated) {
      getNews().then((news) => {
        setValue(_o => ({ loaded: true, news, lastUpdated: Date.now() }));
      }).catch(() => {
        setValue(_o => ({ loaded: true, news: [], lastUpdated: Date.now() }));
      });
    }
  }, [lastUpdated, value.lastUpdated, logged]);

  if (false === value.loaded) {
    return <></>;
  }

  ('development' === process.env.NODE_ENV) && console.log('[b4-return] NewsProvider');
  return (
    <NewsContext.Provider value={value}>
      {props.children}
    </NewsContext.Provider>
  )
}
