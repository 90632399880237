import React, { useContext } from 'react';
import ModalDialog from '../../../components/ui/ModalDialog';
import { AuthContext } from '../../../utils/providers/AuthProvider';
import { timestampToDateFormat } from '../../../libs/phpHelpers';

export default function ModalDialogMaintenance() {
  const { isMaintenanceMode, logged } = useContext(AuthContext);

  if (!logged) {
    return null;
  }

  return isMaintenanceMode ? <ModalDialog title="維護模式" wrapperClassName="modal-news" alias="maintenance" showClose={false}>
    <h3 dangerouslySetInnerHTML={{
      __html: logged.__.maintenanceModeMessage
        .replace(/\{DATE_START\}/, timestampToDateFormat(logged.__.maintenanceModeStartOn))
        .replace(/\{DATE_END\}/g, timestampToDateFormat(logged.__.maintenanceModeEndOn))
    }}></h3>
  </ModalDialog> : null
}
