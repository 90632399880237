import { memo, useContext } from 'react';
// import { UNIT_TEXT } from '../arena';
import { ArenaContext } from '../../utils/providers/ArenaProvider';

export default memo(function(props: { index: string | undefined }) {
  const { value } = useContext(ArenaContext);
  const arenaUnit = value.arenas.find(x=> parseInt(props.index as string) === x.id);
  if (!arenaUnit) {
    return <></>;
  }

  return (
    <div className={`page-arena-title page-arena-title-${props.index}`}>
      {/* <div className="page-arena-title-bg">{UNIT_TEXT[parseInt(props.index as string)]}</div>
      <div className="page-arena-title-text">{UNIT_TEXT[parseInt(props.index as string)]}</div> */}
      <div className="page-arena-title-bg"><span>{arenaUnit.name}{arenaUnit.sub ? <span className="sub">（{arenaUnit.sub}）</span> : ''}</span></div>
      <div className="page-arena-title-text"><span>{arenaUnit.name}{arenaUnit.sub ? <span className="sub">（{arenaUnit.sub}）</span> : ''}</span></div>
    </div>);
})
