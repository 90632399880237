import { useContext, useState } from 'react';
import { Link } from "react-router-dom";
import SvgButton from '../../components/ui/SvgButton';
import { ArenaContext } from "../../utils/providers/ArenaProvider";

interface PageArenaIndexChooserProps {
  index: number,
}

export default function PageArenaIndexChooser(props: PageArenaIndexChooserProps) {
  const { index } = props;
  const { value } = useContext(ArenaContext);
  const [show, setShow] = useState(false);

  return <Link to={`/arena/summary/${props.index}`}>
    <SvgButton className="" prefix="/assets/arena/battle-button-" src1={`${props.index}.svg`} src2={`${props.index}-select.svg`} alt=""></SvgButton>
    {value.unitTopest[index] >= 16 ? <img onMouseOver={() => setShow(true)} onMouseOut={() => setShow(false)} className="trophy" src="/assets/arena/battle-icon.svg" alt="" /> : null}
    {show ? <div className={`info info-${props.index}`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/arena/battle-popup-4.svg)` }}>
      <div className="info-row">
        <span className="base">{value.unitTopest[index] ?? 0} / 30 題</span>
      </div>
      <div className="info-row">
        <span className="base">{value.correctQuestions[index] ?? 0} / {value.questionCountByUnit[index]} 題</span>
      </div>
    </div> : null}
  </Link>
}
