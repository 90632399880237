import React, { useEffect } from 'react';
// import useDevicePixelRatio from '../../utils/hooks/useDevicePixelRatio';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { useAppTemplateContext } from '../../utils/providers/AppTemplateProvider';

// const isMobile = () => {
//   const ua = navigator.userAgent;
//   return /Android|Mobi/i.test(ua);
// };

interface WindowProps {
  onWindowResize?: () => void
}

// const windowInnerHeight = window.innerHeight;

export default function Window(props: WindowProps) {
  const size = useWindowSize();
  const { version } = useAppTemplateContext();

  useEffect(() => {
    // setInterval(() => {
      // let zoom = ((document.getElementById('measurer') as HTMLDivElement).offsetWidth / window.innerWidth);
      if ('AppV4' === version) {
        (document.querySelector('#root > #app') as HTMLDivElement).style.transform = ``;
      } else {
      let scale = 1;
      if ((window.innerWidth / window.innerHeight) > (1920 / 1080)) {
        scale = window.innerHeight / 1080;
      } else {
        scale = window.innerWidth / 1920;
      }
// alert(scale);
      (document.querySelector('#root > #app') as HTMLDivElement).style.transform = `scale(${scale})`;
    }
      // (document.querySelector('#root') as HTMLDivElement).style.width = `scale(${scale})`;
      // (document.querySelector('#root') as HTMLDivElement).style.height = `scale(${scale})`;
      // console.log('zoom', zoom);
    // }, 1000);
  }, [version]);

  useEffect(() => {
    // const canListen = typeof window !== 'undefined' && 'matchMedia' in window
    // if (!canListen) {
    //   return
    // }

    // let ws = window.screen as any;
    // if (ws.systemXDPI !== undefined && ws.logicalXDPI !== undefined && ws.systemXDPI > ws.logicalXDPI) {
    //   // Only allow for values > 1
    //   console.log(ws.systemXDPI / ws.logicalXDPI);
    // }

    // console.log('update', {
    //   systemXDPI: ws.systemXDPI,
    //   logicalXDPI: ws.logicalXDPI,
    //   // visualViewport: window.visualViewport,
    //   // level: window.visualViewport.height / windowInnerHeight,
    //   level2: Math.round(100 * 1 / (window.visualViewport.height / windowInnerHeight)) / 100,
    //   vh: window.visualViewport.height,
    //   wh: windowInnerHeight,
    //   dh: document.documentElement.clientHeight,
    // });

    // window.visualViewport.addEventListener('resize', () => {
    //   let zoomLevel = Math.round(100 * 1 / (window.innerHeight / 1080)) / 100;
    //   // console.log('vvp resize', zoomLevel);
    //   // const updateDpr = () => {
    //   //   console.log('updateDpr');
    //   //   let newDpr = 10 * getDevicePixelRatio() / 10;
    //   //
    //   // @ts-ignore
    //   document.querySelector('#root').style.setProperty('--zoom', zoomLevel);
    //   // @ts-ignore
    //   document.querySelector('#root').style.setProperty('--size-by-aspect-ratio', (window.innerWidth / window.innerHeight > 16 / 9) ? '1' : '2');
    //   document.body.classList.remove('zoom-gt-1', 'zoom-lte-1');
    //   document.body.classList.add(zoomLevel > 1 ? 'zoom-gt-1' : 'zoom-lte-1');
    // })
    //
    //   setCurrentDpr(() => newDpr)
    //
    // }
    // const mediaMatcher = window.matchMedia(`screen and (resolution: ${currentDpr}dppx)`)
    // mediaMatcher.addEventListener('change', updateDpr)
    //
    // updateDpr();
    //
    // return () => {
    //   mediaMatcher.removeEventListener('change', updateDpr)
    // }
  }, [size])

  // useEffect(() => {
  //   const addEventListeners = () => {
  //     window.addEventListener("resize", () => {
  //       onWindowResize()
  //       props.onWindowResize && props.onWindowResize();
  //     });
  //   };
  //
  //   const removeEventListeners = () => {
  //     window.removeEventListener("resize", () => {
  //       onWindowResize()
  //       props.onWindowResize && props.onWindowResize();
  //     });
  //   };
  //
  //   if (typeof navigator !== "undefined" && isMobile()) {
  //
  //   } else {
  //     onWindowResize();
  //     props.onWindowResize && props.onWindowResize();
  //     addEventListeners();
  //     return () => removeEventListeners();
  //   }
  // }, [props]);
  //
  // const onWindowResize = () => {
  //   console.log('window.tsx', window.devicePixelRatio)
  //
  //   // @ts-ignore
  //   document.querySelector('#root').style.setProperty('--zoom', window.devicePixelRatio);
  //   // @ts-ignore
  //   document.querySelector('#root').style.setProperty('--size-by-aspect-ratio', (window.innerWidth / window.innerHeight > 16 / 9) ? '1' : '2');
  //   document.body.classList.remove('zoom-gt-1', 'zoom-lte-1');
  //   document.body.classList.add(window.devicePixelRatio > 1 ? 'zoom-gt-1' : 'zoom-lte-1');
  // }
  //
  // if (typeof navigator !== "undefined" && isMobile()) return null;

  return (
    <>
    </>
  );
};
