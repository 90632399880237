import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { authHeartbeat, authSignOut } from '../api';
import User from '../models/User';

interface Props {
  children: ReactNode,
}

interface Context {
  // jwt?: string,
  // jwtExpiry?: Date,
  isMaintenanceMode: boolean,
  loaded: boolean,
  logged?: User,
  isAuthenticated: boolean,
  lastUpdated: number,
  signIn: (logged: User, cb: () => void) => void,
  signOut: (cb: () => void) => void,
  forceUpdate: (cb: () => void) => void,
}

const DEFAULTCONTEXT: Context = {
  isMaintenanceMode: false,
  loaded: false,
  isAuthenticated: false,
  lastUpdated: 0,
  signIn: (_logged: User, _cb: () => void) => { },
  signOut: (_cb: () => void) => { },
  forceUpdate: (_cb: () => void) => { },
};

export const AuthContext = createContext<Context>(DEFAULTCONTEXT);

export const useAuthContext = () => useContext(AuthContext);

export default function AuthProvider(props: Props) {
  const [value, setValue] = useState<Context>(DEFAULTCONTEXT);

  useEffect(() => {
    setValue(o => ({
      ...o,
      // loaded: true,
      signIn: (logged: User, cb: () => void) => {
        console.log('AuthProvider setValue');

        authHeartbeat().then((d) => {
          setValue(o => ({
            ...o,
            loaded: true,
            logged: d,
            isAuthenticated: true,
            lastUpdated: Date.now(),
          }))
          cb();
        }).catch(() => {
        });
        // setValue(o => ({ ...o, logged, isAuthenticated: true, }))
        // console.log('AuthProvider setTimeout');
        // setTimeout(()=>{
        // }, 300);
      },
      signOut: (cb: () => void) => {
        authSignOut().then(() => {
          setValue(o => ({ ...o, logged: undefined, isAuthenticated: false, lastUpdated: Date.now(), }))
          cb();
        });
      },
      forceUpdate: (cb: () => void) => {
        authHeartbeat().then((d) => {
          setValue(o => ({ ...o, logged: d, lastUpdated: Date.now() }));
          cb();
        }).catch(() => {
        });
      },
    }));
  }, []);

  // useEffect(() => {
  //   setInterval(() => authHeartbeat().then(hb => {
  //     if (hb && hb.__ && value.isMaintenanceMode !== hb.__.isMaintenanceMode) {
  //       console.log('heartbeat', 'isMaintenanceMode changed', value.isMaintenanceMode, hb.__.isMaintenanceMode);
  //       // console.log('heartbeat', 'isMaintenanceMode updated')
  //       setValue(o => ({
  //         ...o,
  //         isMaintenanceMode: hb.__.isMaintenanceMode,
  //       }));
  //     } else {
  //       console.log('heartbeat', 'isMaintenanceMode changed', value.isMaintenanceMode);
  //     }
  //   }), 15 * 1000);
  // }, [value.isMaintenanceMode]);

  useEffect(() => {
    authHeartbeat().then((d) => {
      if (d && d.__) {
        setValue(o => ({
          ...o,
          loaded: true,
          logged: d,
          isAuthenticated: true,
          lastUpdated: Date.now(),
          isMaintenanceMode: d.__.isMaintenanceMode,
        }))
      } else {
        setValue(o => ({
          ...o,
          loaded: true,
          logged: undefined,
          isAuthenticated: false,
          lastUpdated: Date.now(),
          isMaintenanceMode: false,
        }))
      }
    }).catch(() => {
      setValue(o => ({
        ...o,
        loaded: true,
        logged: undefined,
        isAuthenticated: false,
        lastUpdated: Date.now(),
        isMaintenanceMode: false,
      }))
    });
  }, []);

  if (false === value.loaded) {
    return <></>;
  }

  ('development' === process.env.NODE_ENV) && console.log('[b4-return] AuthProvider', value);
  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  )
}
