import { useEffect, useState } from 'react';
import { getArenaTeacherReportUrl, getArenaTeacherDownloadFilter } from '../../utils/api';

interface PageArenaTeacherDownloadProps {
}

export default function PageArenaTeacherDownload(props: PageArenaTeacherDownloadProps) {
  // const { units } = useContext(UnitContext);
  const [filterOptions, setFilterOptions] = useState<{
    class: Array<[string, string]>,
    chapter: Array<[number, string]>,
  }>({
    class: [],
    chapter: [],
  });
  const [formValue, setFormValue] = useState({
    class: "",
    chapter: "",
    date_from: "",
    date_to: "",
  })

  const openDownloadUrl = () => {
    let targetUrl = getArenaTeacherReportUrl() + '?' + Object.entries(formValue).filter(([key, v]) => "" !== v).map(([key, v]) => key + '=' + encodeURIComponent(v)).join('&');
    console.log(formValue, targetUrl);
    window.open(targetUrl);
  }

  useEffect(() => {
    getArenaTeacherDownloadFilter().then(r=>{
      console.log(r);
      setFilterOptions({
        class: r.levelsClasses.map(x => `${x.level}____${x.class}`).filter((v, i, a) => i === a.indexOf(v)).sort().map(x => [x, x.split('____').join(' - ')]),
        chapter: r.arenaUnits.map(x => ([x.id, x.name])),
      });
    })
  }, []);

  return (
    <div className="dialog-teacher">
      <h3>只限教師 - 下載</h3>

      <div className="dialog-teacher-filter">
        <label htmlFor="class">Class:</label>
        <div className="dialog-teacher-filter-field">
          <select name="class" value={formValue.class} onChange={(e) => setFormValue(o => ({ ...o, class: e.target.value }))}>
            <option value="">-- All --</option>
            {filterOptions.class.map(x => <option value={x[0]}>{x[1]}</option>)}
          </select>
        </div>
      </div>
      <div className="dialog-teacher-filter">
        <label htmlFor="chapter">Chapter:</label>
        <div className="dialog-teacher-filter-field">
          <select name="chapter" value={formValue.chapter} onChange={(e) => setFormValue(o => ({ ...o, chapter: e.target.value }))}>
            <option value="">-- All --</option>
            {filterOptions.chapter.map(x => <option value={x[0]}>{x[1]}</option>)}
          </select>
        </div>
      </div >
      <div className="dialog-teacher-filter">
        <label htmlFor="date_from">From:</label>
        <div className="dialog-teacher-filter-field">
          <input type="date" name="date_from" value={formValue.date_from} onChange={(e) => setFormValue(o => ({ ...o, date_from: e.target.value }))} />
        </div>
      </div>
      <div className="dialog-teacher-filter">
        <label htmlFor="date_to">To:</label>
        <div className="dialog-teacher-filter-field">
          <input type="date" name="date_to" value={formValue.date_to} onChange={(e) => setFormValue(o => ({ ...o, date_to: e.target.value }))} />
        </div>
      </div>
      <button className="dialog-btn-reset" onClick={() => setFormValue({ class: '', chapter: '', date_from: '', date_to: '' })}>重置</button>

      <button className="dialog-btn-download" onClick={() => openDownloadUrl()}>下載本校紀錄</button>
    </div >
  );
}
