import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

interface Props {
  children: ReactNode,
}

const VERSIONS = ["App", "AppV4"] as const;

export type VERSION = typeof VERSIONS[number];

interface Context {
  loaded: boolean,
  version: VERSION,
  setVersion: (_v: VERSION) => void,
}

const DEFAULTCONTEXT: Context = {
  loaded: false,
  version: 'App',
  setVersion: (_v: VERSION) => { },
};

export const AppTemplateContext = createContext<Context>(DEFAULTCONTEXT);
export const useAppTemplateContext = () => useContext(AppTemplateContext);

export default function AppTemplateProvider(props: Props) {
  const [value, setValue] = useState<Context>(DEFAULTCONTEXT);

  const doV4Style = useCallback(() => {
    const root = document.querySelector('#root') as HTMLDivElement;
    if (null !== root) {
      let iOS = (('ontouchstart' in window) || ((navigator as any).MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
      // alert('iOS: ' + (iOS ? '1' : '0'));
      root.style.setProperty('--v4-ios', iOS ? '1' : '0');
      root.style.setProperty('--v4-width', window.innerWidth + '');
      root.style.setProperty('--v4-height', window.innerHeight + '');
      root.style.setProperty('--v4-left-px', (-(1920 - window.innerWidth) / 2) + 'px');
      root.style.setProperty('--v4-top-px', (-(1080 - window.innerHeight) / 2) + 'px');
    }
  }, [])

  useEffect(() => {
    setValue(o => ({
      ...o,
      loaded: true,
      setVersion: (version: VERSION) => {
        if (value.version === version) {
          return;
        }

        const divRoot = document.querySelector<HTMLDivElement>('#root');
        const divApp = document.querySelector<HTMLDivElement>('#root > #app');
        if ("AppV4" === version) {
          document.body.classList.add('v4');
        } else {
          document.body.classList.remove('v4');
        }
        if (divRoot) {
          if ("AppV4" === version) {
            divRoot.classList.add('v4');
          } else {
            divRoot.classList.remove('v4');
          }
        }
        if (divApp) {
          divApp.style.backgroundImage = `url('/assets-v4/login/login_bg_day.png')`;
          VERSIONS.forEach(v => {
            if (version === v) {
              divApp.classList.add(v);
            } else {
              divApp.classList.remove(v);
            }
          })
        }
        doV4Style();
        console.log('do setVersion', value.version, version);
        setValue(o => ({ ...o, version }));
      },
    }))
  }, [doV4Style, value.version]);

  useEffect(() => {
    window.addEventListener('resize', doV4Style);
    return () => {
      window.removeEventListener('resize', doV4Style)
    }
  }, [doV4Style]);

  useEffect(() => {
    // const onOrientationchange = () => {
    //   window.location.reload();
    // }
    window.addEventListener('orientationchange', doV4Style);
    return () => {
      window.removeEventListener('orientationchange', doV4Style)
    }
  }, [doV4Style]);

  if (!value.loaded) {
    return null;
  }

  console.log('[b4-return] AppTemplateProvider', value);
  return (
    <AppTemplateContext.Provider value={value}>
      {props.children}
    </AppTemplateContext.Provider>
  )
}
