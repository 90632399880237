import React, { memo, useContext, useEffect, useState } from 'react';
import SvgButton from '../../ui/SvgButton';
import Modal from '../../ui/Modal';
import { getHeader, getMyUnitsResult, getUserRankingScore } from '../../../utils/api';
// import MyArenaResult from '../../../utils/models/joined/MyArenaResult';
import ModelHeader from '../../../utils/models/joined/Header';
import MyUnitResult from '../../../utils/models/joined/MyUnitResult';
import ScrollbarVertical from '../ScrollbarVertical';
import ProfileIconSelect from './ProfileIconSelect';
import { UnitContext } from '../../../utils/providers/UnitProvider';
// import { getPassedUnitCount } from '../../../pages/arena'
import { getLevelBySumScore } from '../../../pagesV4/constants';

export const LevelTextMap = ['書生', '秀生', '舉人', '貢士', '進士', '探花', '探花', '榜眼', '榜眼', '狀元', '狀元', '狀元',];

interface ProfileProps {
  handleClick: (i: number) => void,
  onClose: () => void,
}

function Profile(props: ProfileProps) {
  const [userInfo, setUserInfo] = useState<{
    userInfo: null | ModelHeader,
    unitMarks: Array<MyUnitResult>,
    unitMarksByUnitId: { [unidId: number]: MyUnitResult },
    // myArenaResult: MyArenaResult,
    totalMarks: number,
    level: string,
  }>({
    userInfo: null,
    unitMarks: [],
    unitMarksByUnitId: {},
    // myArenaResult: {
    //   countUnit: {},
    //   unitTopest: {},
    //   questionCountByUnit: {},
    //   correctQuestions: {},
    //   arenas: [],
    // },
    totalMarks: 0,
    level: '',
  });
  const { units } = useContext(UnitContext);

  useEffect(() => {
    Promise.all([
      getHeader(),
      getMyUnitsResult(),
      getUserRankingScore(),
      // getMyArenaResult(),
    ]).then(([
      userInfo, unitMarks,
      userRankingScore,
      // myArenaResult
    ]) => {
      console.log(userRankingScore);
      let totalMarks = parseInt(userRankingScore[0].sum_score);
      // console.log(unitMarks);
      setUserInfo({
        userInfo, unitMarks, unitMarksByUnitId: Object.fromEntries(unitMarks.map(x => [x.unit_id, x])),
        // totalMarks: unitMarks.reduce((p, c) => p + parseInt(c.totalScore), 0),
        // myArenaResult
        totalMarks,
        level: getLevelBySumScore(totalMarks),
      });
    });
    // setLevelMarks([1,2,3,4,5]);
  }, []);

  if (null === userInfo.userInfo) {
    return (
      <Modal className="modal-profile">
        <div className="dialog" style={{ backgroundImage: "url(/assets/profile/account-dialog.svg)" }}>
        </div>
      </Modal>
    )
  }
  console.log(units, userInfo.unitMarksByUnitId);

  return (
    <Modal className="modal-profile">
      <div className="dialog" style={{ backgroundImage: "url(/assets/profile/account-dialog.svg)" }}>
        <button className="dialog-btn-close" aria-label="關閉" onClick={() => props.onClose()}><SvgButton prefix="/assets/profile/" src1="account-button-close.svg" src2="account-button-close-select.svg" alt="關閉"></SvgButton></button>

        <div className="header">
          <div className="left">
            <ProfileIconSelect userInfo={userInfo.userInfo} unitMarks={userInfo.unitMarks} handleClick={props.handleClick} />
          </div>
          <div className="right">
            <div className="user" style={{ backgroundImage: "url(/assets/profile/account-yellowbar.svg)" }}>
              <span className="name">{userInfo.userInfo.cname}</span>
              <span className="schoolName">{userInfo.userInfo.cSchoolName}</span>
              <span className="classNo">({userInfo.userInfo.classNo})</span>
            </div>
            <div className="marks">
              <div className="star" style={{ backgroundImage: "url(/assets/profile/account-greybar.svg)" }}>
                <img src="/assets/island/course1-scorebar-3-01-01.svg" alt="星" />
                <span>{userInfo.userInfo._.viewUserTotalStars}</span>
              </div>
              <div className="score" style={{ backgroundImage: "url(/assets/profile/account-greybar.svg)" }}>
                <span>{userInfo.totalMarks}</span>
              </div>
              <div className="level" style={{ backgroundImage: "url(/assets/profile/account-greybar.svg)" }}>
                <span>{userInfo.level}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="my-units-result">
          <ScrollbarVertical>
            <div>
              {units.map(({ name, lv1id }) => {
                // console.log(lv1id, userInfo.unitMarksByUnitId[lv1id])
                let temp = userInfo.unitMarksByUnitId[lv1id] ?? {
                  unit_name: name,
                  totalStar: 0,
                };
                return <div className="mark" key={lv1id}>
                  <div className="title">{temp.unit_name}</div>
                  <div className="details">
                    <div className="progress">
                      <div className="progress-bar" style={{ backgroundImage: "url(/assets/profile/account-scorebar1.svg)" }}>
                        <svg viewBox="0 0 459 44" style={{}}>
                          <line x1={24} y1={20} x2={(24) + (395) * parseInt(temp.totalStar) / 7} y2={20} stroke="#51AF2E" strokeLinecap="round" strokeWidth={20} />
                        </svg>
                      </div>
                      <span className="progress-percent">{Math.round(100 * parseInt(temp.totalStar) / 7)}%</span>
                    </div>
                    <div className="star">
                      <img alt="分數" src="/assets/island/course1-scorebar-3-01-01.svg" />
                      <span>{temp.totalStar} / 7</span>
                    </div>
                  </div>
                </div>
              })}
            </div>
          </ScrollbarVertical>
        </div>
      </div>
    </Modal>
  )
}

export default memo(Profile);
