import { cloneElement, PropsWithChildren, ReactElement } from 'react';

interface BgRowAbsProps {
  className?: string,
  component: ReactElement,
  cClassname?: string,
  onClick?: () => void,
}

function BgRowAbs({ className, component, children, cClassname, onClick, ...rest }: PropsWithChildren<BgRowAbsProps>): JSX.Element {
  // const Com = component as keyof JSX.IntrinsicElements;

  return cloneElement(component, {
    ...rest,
    className,
    onClick,
    children: <>
      <div className={`${className}-l`}></div>
      <div className={`${className}-c ${cClassname}-c`}>{children}</div>
      <div className={`${className}-r`}></div>
    </>
  })
  // return (
  //   <Com className={className} {...rest}>
  //     <div className={`${className}-l`}></div>
  //     <div className={`${className}-c`}>{children}</div>
  //     <div className={`${className}-r`}></div>
  //   </Com>
  // )
}

BgRowAbs.defaultProps = {
  component: <div></div>,
}

export default BgRowAbs;
