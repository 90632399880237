import { memo, useContext, useEffect, useState } from 'react';
import SvgButton from '../components/ui/SvgButton';
import { getRanking, getTeacherReportUrl } from '../utils/api';
import { AuthContext } from '../utils/providers/AuthProvider';
import { UnitContext } from '../utils/providers/UnitProvider';
import LeaderboardUser from '../utils/models/LeaderboardUser';
import ScrollbarVertical from '../components/project-ui/ScrollbarVertical';
import './leaderboard.scss';

interface DialogTabProps {
  text: string,
  active?: boolean,
  onClick?: () => void,
}
function DialogTab(props: DialogTabProps) {
  return (
    <button className="dialog-tab" onClick={() => props.onClick ? props.onClick() : {}} style={{ backgroundImage: props.active ? "none" : "url(/assets/settings/leaderboard/setting-leaderboard-button-01.svg)" }}>
      <h3 className="dialog-tab-title">{props.text}</h3>
    </button>
  );
}

function LeaderboardUserItem(props: { user: LeaderboardUser }) {
  return (
    <div className="leaderboard-user-item" style={{ backgroundImage: 'url(/assets/settings/leaderboard/setting-leaderboard-bar1-01.svg)' }}>
      {/* <img src="" />
      <img src="/assets/settings/leaderboard/setting-leaderboard-bar2-01.svg" /> */}
      <div className="leaderboard-user-item-icon" style={{ backgroundImage: 'url(/assets/header/mainbar-icon-01.svg)' }}></div>
      <div className="leaderboard-user-item-name">{props.user.cname} - {props.user.cSchoolName}</div>
      <div className="leaderboard-user-item-mark" style={{ backgroundImage: 'url(/assets/island/course1-scorebar-3-01-01.svg)' }}>{parseInt(props.user.sum_star)} / {parseInt(props.user.sum_score)}</div>
    </div>
  )
}

interface LeaderboardAreaProps {
  tab: number,
}

function LeaderboardArea(props: LeaderboardAreaProps) {
  const [data, setData] = useState<LeaderboardUser[]>([]);
  const [loaded, setLoaded] = useState(false);
  const { logged } = useContext(AuthContext);

  useEffect(() => {
    getRanking(props.tab === 1 ? 'all' : 'school').then(r => {
      setData(r);
      setLoaded(true);
    });
  }, [props.tab])

  if (!loaded) {
    return <></>;
  }

  return (
    // style={{ backgroundImage: "url(/assets/settings/leaderboard/setting-leaderboard-scrolldown-1.svg)" }}
    <>
      <div className="leaderboard-area">
        <ScrollbarVertical>
          {/* <p>Hello world!</p> */}
          <div className="leaderboard-area-inner">
            {data.map(leaderboardUser => <LeaderboardUserItem key={leaderboardUser.userID} user={leaderboardUser} />)}
          </div>
        </ScrollbarVertical>
      </div>

      {logged && 'Teacher' === logged.role ? <PageLeaderboardTeacherDownload data={data} /> : null}
    </>
  )
}

interface PageLeaderboardTeacherDownloadProps {
  data: LeaderboardUser[],
}

export function PageLeaderboardTeacherDownload(props: PageLeaderboardTeacherDownloadProps) {
  const { units } = useContext(UnitContext);
  const [filterOptions, setFilterOptions] = useState<{
    class: Array<[string, string]>,
    chapter: Array<[number, string]>,
  }>({
    class: [],
    chapter: [],
  });
  const [formValue, setFormValue] = useState({
    class: "",
    chapter: "",
    date_from: "",
    date_to: "",
  })

  const openDownloadUrl = () => {
    console.log(formValue);
    window.open(getTeacherReportUrl() + '?' + Object.entries(formValue).filter(([key, v]) => "" !== v).map(([key, v]) => key + '=' + encodeURIComponent(v)).join('&'))
  }

  useEffect(() => {
    setFilterOptions({
      class: props.data.map(x => `${x.level}____${x.class}`).filter((v, i, a) => i === a.indexOf(v)).sort().map(x => [x, x.split('____').join(' - ')]),
      chapter: units.map(x => ([x.id, x.name])),
    });
  }, [props.data, units]);

  return (
    <div className="dialog-teacher">
      <h3>只限教師 - 下載</h3>

      <div className="dialog-teacher-filter">
        <label htmlFor="class">Class:</label>
        <div className="dialog-teacher-filter-field">
          <select name="class" value={formValue.class} onChange={(e) => setFormValue(o => ({ ...o, class: e.target.value }))}>
            <option value="">-- All --</option>
            {filterOptions.class.map(x => <option value={x[0]}>{x[1]}</option>)}
          </select>
        </div>
      </div>
      <div className="dialog-teacher-filter">
        <label htmlFor="chapter">Chapter:</label>
        <div className="dialog-teacher-filter-field">
          <select name="chapter" value={formValue.chapter} onChange={(e) => setFormValue(o => ({ ...o, chapter: e.target.value }))}>
            <option value="">-- All --</option>
            {filterOptions.chapter.map(x => <option value={x[0]}>{x[1]}</option>)}
          </select>
        </div>
      </div >
      <div className="dialog-teacher-filter">
        <label htmlFor="date_from">From:</label>
        <div className="dialog-teacher-filter-field">
          <input type="date" name="date_from" value={formValue.date_from} onChange={(e) => setFormValue(o => ({ ...o, date_from: e.target.value }))} />
        </div>
      </div>
      <div className="dialog-teacher-filter">
        <label htmlFor="date_to">To:</label>
        <div className="dialog-teacher-filter-field">
          <input type="date" name="date_to" value={formValue.date_to} onChange={(e) => setFormValue(o => ({ ...o, date_to: e.target.value }))} />
        </div>
      </div>
      <button className="dialog-btn-reset" onClick={() => setFormValue({ class: '', chapter: '', date_from: '', date_to: '' })}>重置</button>

      <button className="dialog-btn-download" onClick={() => openDownloadUrl()}>下載本校紀錄</button>
    </div >
  );
}

interface PageLeaderboardProps {
  onClose: () => void
}

function PageLeaderboard(props: PageLeaderboardProps) {
  const [tab, setTab] = useState(1);

  return (
    <div className="page-leaderboard dialog" style={{ backgroundImage: "url(/assets/settings/leaderboard/setting-leaderboard-background.svg" }}>
      <div className="dialog-tabs">
        <DialogTab text="排行榜" active={true} />
        {/* <DialogTab text="全港" onClick={() => setTab(1)} active={1 === tab} /> */}
        <DialogTab text="校內" onClick={() => setTab(2)} active={2 === tab} />
      </div>
      {/* <h3 className="dialog-title">設定</h3> */}
      <button className="dialog-btn-close" aria-label="關閉" onClick={() => props.onClose()}><SvgButton prefix="/assets/settings/" src1="setting-button-close.svg" src2="setting-button-close-select.svg" alt="關閉"></SvgButton></button>

      <LeaderboardArea tab={tab}></LeaderboardArea>
    </div >
  )
}

export default memo(PageLeaderboard);
