import { memo, useContext, useEffect, useState } from 'react';
import SpinKit from '../../components/ui/SpinKit';
import SvgButton from '../../components/ui/SvgButton';
import SvgButtonToggle, { ForceMode } from '../../components/ui/SvgButtonToggle';
import { BackgroundContext } from '../../utils/providers/BackgroundProvider';
import ArenaQuestion from '../../utils/models/ArenaQuestion'
import ArenaQuestionDetail from '../../utils/models/ArenaQuestionDetail'
import PageArenaQnaMcImageButton from './arena-mc-img-button';
import PageArenaTitle from './arena-title';
import ScrollbarVertical from '../../components/project-ui/ScrollbarVertical';

type PageArenaQnaMcExtra = 'img'

interface PageArenaQnaMcProps {
  index: string | undefined,
  extras?: Array<PageArenaQnaMcExtra>,
  qna: {
    correctedInSession: number,
    noOfQuestion: number,
    qqID: number,
    question: ArenaQuestion,
    questionDetails: ArenaQuestionDetail,
  },
  questionIndex: number,
  questionTotal: number,
  onSubmit: (questionId: number, ansIndex: string, cb: () => void) => Promise<boolean | void>,
}

function PageArenaQnaMc(props: PageArenaQnaMcProps) {
  const { qna, questionIndex, questionTotal } = props;
  const { setSegment } = useContext(BackgroundContext);
  const [submitted, setSubmitted] = useState(false);

  const [selected, setSelected] = useState([false, false, false, false]);
  const [dim, setDim] = useState<Array<ForceMode>>([null, null, null, null]);
  // const [questionIndex, setQuestionIndex] = useState(0);
  const [randomOrder, setRandomOrder] = useState([1, 2, 3, 4, 5, 6].sort(() => .5 - Math.random()));

  useEffect(() => {
    // setSegment([0, 120]);

    setSegment([120, 240]);
    setSubmitted(false);
  }, [setSegment, questionIndex]);

  useEffect(() => {
    setSelected([false, false, false, false]);
    setDim([null, null, null, null]);
    // console.log(qna);
    let pool = [2, 3, 4];
    if ('' !== qna.questionDetails.option5) { pool.push(5) }
    if ('' !== qna.questionDetails.option6) { pool.push(6) }
    // console.log(pool);
    setRandomOrder([1, ...pool.sort(() => .5 - Math.random()).slice(0, 3)].sort(() => .5 - Math.random()));
  }, [qna.questionDetails, questionIndex]);

  const onToggle = (index: number) => () => {
    setSelected(_o => [0, 0, 0, 0].map((_, i) => index === i));
  }

  const submit = () => {
    if (submitted) {
      return;
    }

    setSubmitted(true);
    let ansIndex = randomOrder[selected.findIndex(x => x)];
    let correct = qna.questionDetails.correctAns === ansIndex;
    let correctAnsRandomIndex = randomOrder.findIndex(x => x === qna.questionDetails.correctAns)
    setDim(_o => [0, 0, 0, 0].map((_, i) => correctAnsRandomIndex === i ? 'src3' : 'src2'));

    if (correct) {
      setSegment([360, 480]);
    } else {
      setSegment([240, 360]);
      // setSelected(_o => [0, 0, 0, 0].map((_, i) => correctAnsRandomIndex === i));
      // setSegment([120, 240]);
    }
    // console.log('submit', correct, correctAnsRandomIndex, qna, randomOrder);
    props.onSubmit(qna.question.id, '' + ansIndex, () => setSubmitted(false)).then();
  }
  console.log(qna);
  if (!qna.question || 0 === qna.qqID) {
    return <></>;
  }
  console.log(qna.questionDetails);

  return (
    <>
      {/* <div className="page-island-qna-pagination" dangerouslySetInnerHTML={{ __html: `${1 === qna.question.level ? '基礎題' : '挑戰題'}第 ${questionIndex} / ${questionTotal} 題` }}></div> */}
      <div className="page-island-qna-pagination">
        {/* <div className="page-island-qna-pagination-stage" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/arena/battle-score-bar-item${props.index}.svg)` }}> */}
        <div className="page-island-qna-pagination-stage">
          <PageArenaTitle index={props.index} />
        </div>
        <div className={`page-island-qna-pagination-progress page-island-qna-pagination-progress-${props.index}`}>
          <svg viewBox="0 0 46 7" style={{ height: '100%' }}>
            {/* <line x1="3.5" y1="3.5" x2={3.5 + 40} y2="3.5" stroke="#CC3" strokeLinecap="round" strokeWidth="3" /> */}
            <rect strokeWidth=".5" fill="white" x="2.25" width="42.5" height="2.5" rx="1.5" y="2.25"></rect>
            <line x1="3.5" y1="3.5" x2={3.5 + 40 * qna.correctedInSession / questionTotal} y2="3.5" strokeLinecap="round" strokeWidth="2.5" />
            <image href={`${process.env.PUBLIC_URL}/assets/arena/battle-icon.svg`} height="5" width="5" y="1" x={3.5 + 40 * 24 / 30 - 2.5}></image>
          </svg>
        </div>
        <div className="page-island-qna-pagination-index">
          <span></span>
          <span>{`${questionIndex} / ${questionTotal} 題`}</span>
        </div>
      </div>
      <div className="page-island-qna-title">
        <ScrollbarVertical>
          <div className="page-island-qna-title-text" dangerouslySetInnerHTML={{ __html: qna.questionDetails.name }}></div>
        </ScrollbarVertical>
      </div>
      {/* <PageArenaQnaMcImageButton question={qna.question} questionDetails={qna.questionDetails} /> */}
      {props.extras && -1 !== props.extras.indexOf('img')
        ? <PageArenaQnaMcImageButton question={qna.question} questionDetails={qna.questionDetails} />
        : null}
      {/* <div className="page-island-qna-question" dangerouslySetInnerHTML={{ __html: qna.questionDetails.explanation }}></div> */}
      <div className="page-island-qna-answer">
        <SvgButtonToggle forceMode={dim[0]} selected={selected[0]} onClick={onToggle(0)} className={`${selected[0] ? 'selected' : ''} ${(qna.questionDetails as any)['option' + randomOrder[0]].length > 20 ? 'text-small' : ''}`} prefix="/assets/island/" src1="course1-qna-mc-button-1.svg" src2="course1-qna-mc-button-mouseover.svg" src3="course1-qna-mc-button-select-1.svg">
          <span className={'development' === process.env.NODE_ENV && 1 === randomOrder[0] ? 'correct' : ''}>A {(qna.questionDetails as any)['option' + randomOrder[0]]}</span>
        </SvgButtonToggle>
        <SvgButtonToggle forceMode={dim[1]} selected={selected[1]} onClick={onToggle(1)} className={`${selected[1] ? 'selected' : ''} ${(qna.questionDetails as any)['option' + randomOrder[1]].length > 20 ? 'text-small' : ''}`} prefix="/assets/island/" src1="course1-qna-mc-button-2.svg" src2="course1-qna-mc-button-mouseover.svg" src3="course1-qna-mc-button-select-2.svg">
          <span className={'development' === process.env.NODE_ENV && 1 === randomOrder[1] ? 'correct' : ''}>B {(qna.questionDetails as any)['option' + randomOrder[1]]}</span>
        </SvgButtonToggle>
        <SvgButtonToggle forceMode={dim[2]} selected={selected[2]} onClick={onToggle(2)} className={`${selected[2] ? 'selected' : ''} ${(qna.questionDetails as any)['option' + randomOrder[2]].length > 20 ? 'text-small' : ''}`} prefix="/assets/island/" src1="course1-qna-mc-button-3.svg" src2="course1-qna-mc-button-mouseover.svg" src3="course1-qna-mc-button-select-3.svg">
          <span className={'development' === process.env.NODE_ENV && 1 === randomOrder[2] ? 'correct' : ''}>C {(qna.questionDetails as any)['option' + randomOrder[2]]}</span>
        </SvgButtonToggle>
        <SvgButtonToggle forceMode={dim[3]} selected={selected[3]} onClick={onToggle(3)} className={`${selected[3] ? 'selected' : ''} ${(qna.questionDetails as any)['option' + randomOrder[3]].length > 20 ? 'text-small' : ''}`} prefix="/assets/island/" src1="course1-qna-mc-button-4.svg" src2="course1-qna-mc-button-mouseover.svg" src3="course1-qna-mc-button-select-4.svg">
          <span className={'development' === process.env.NODE_ENV && 1 === randomOrder[3] ? 'correct' : ''}>D {(qna.questionDetails as any)['option' + randomOrder[3]]}</span>
        </SvgButtonToggle>
      </div>
      <button className={`page-island-qna-submit ${submitted ? 'disabled' : ''}`} onClick={submit}>
        <SvgButton prefix="/assets/island/" src1="course1-button-submit.svg" src2="course1-button-submit-mouseover.svg" alt="提交" />
        <div className="page-island-qna-submit-spin">
          <SpinKit type="chase" />
        </div>
      </button>
    </>
  )
}

export default memo(PageArenaQnaMc);
