import { memo, useState } from 'react';
import Modal from '../../components/ui/Modal';
import SvgButton from '../../components/ui/SvgButton';
import ArenaQuestion from '../../utils/models/ArenaQuestion';
import ArenaQuestionDetail from '../../utils/models/ArenaQuestionDetail';

interface PageArenaQnaMcImageButtonProps {
  question: ArenaQuestion,
  questionDetails: ArenaQuestionDetail,
}

function PageArenaQnaMcImageButton(props: PageArenaQnaMcImageButtonProps) {
  const { questionDetails } = props;
  const [show, setShow] = useState(false);


  return (
    <>
      <div className="page-island-qna-btn-img-popup-wrapper" onClick={() => setShow(true)}>
        <button className="page-island-qna-btn-img-popup">
          <SvgButton prefix={`${process.env.PUBLIC_URL}/assets/arena/`} src1="pictureicon-button.svg" src2="pictureicon-button-select.svg" alt="Popup"></SvgButton>
          <div>點擊上圖閱覽圖片</div>
        </button>
      </div>
      {show ? <Modal>
        <div className={`page-island-qna-img-popup dialog`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/arena/battle-popup-empty.svg)` }}>
          <button className="dialog-btn-close" aria-label="關閉" onClick={() => setShow(false)}><SvgButton prefix="/assets/settings/" src1="setting-button-close.svg" src2="setting-button-close-select.svg" alt="關閉"></SvgButton></button>
          <a className="page-island-qna-img-popup-btn-download" target="_blank" rel="noreferrer" href={`${process.env.PUBLIC_URL}/files/arena-mc-img/${questionDetails.img}.png`}>[下載原始檔案]</a>
          <div className="dialog-content">
            <img src={`${process.env.PUBLIC_URL}/files/arena-mc-img/${questionDetails.img}_t.png`} alt="" />
          </div>
        </div>
      </Modal> : null}
    </>
  )
}

export default memo(PageArenaQnaMcImageButton);
