import React, { createContext, ReactNode, useEffect, useState } from 'react';
import MyArenaResult from '../models/joined/MyArenaResult';
import { getMyArenaResult } from '../api';

interface Props {
  children: ReactNode,
}

interface Context {
  // jwt?: string,
  // jwtExpiry?: Date,
  loaded: boolean,
  value: MyArenaResult,
  lastUpdated: number,
  forceUpdate: (cb: () => void) => void,
}

const DEFAULTCONTEXT: Context = {
  loaded: false,
  value: {
    countUnit: {},
    unitTopest: {},
    questionCountByUnit: {},
    correctQuestions: {},
    arenas: [],
  },
  lastUpdated: 0,
  forceUpdate: (_cb: () => void) => { },
};

export const ArenaContext = createContext<Context>(DEFAULTCONTEXT);

export default function ArenaProvider(props: Props) {
  const [value, setValue] = useState<Context>(DEFAULTCONTEXT);

  useEffect(() => {
    setValue(o => ({
      ...o,
      loaded: true,
      forceUpdate: (cb: () => void) => {
        getMyArenaResult().then((d) => {
          setValue(o => ({ ...o, value: d, lastUpdated: Date.now() }));
          cb();
        });
      },
    }));
  }, []);

  useEffect(() => {
    getMyArenaResult().then((d) => {
      setValue(o => ({
        ...o,
        value: d,
        isArenaenticated: true,
        lastUpdated: Date.now(),
      }))
    });
  }, []);

  if (false === value.loaded) {
    return <></>;
  }

  ('development' === process.env.NODE_ENV) && console.log('[b4-return] ArenaProvider');
  return (
    <ArenaContext.Provider value={value}>
      {props.children}
    </ArenaContext.Provider>
  )
}
