import { memo, useContext } from 'react';
import { Redirect, useLocation } from "react-router";
import { UnitContext } from '../utils/providers/UnitProvider';
// export const UnitToIndexMap = [4, 8, 10, 12, 36, 42, 30];
export const TextMap = ['史前', '秦漢', '三國', '隋唐', '宋元', '元朝', '明代'];
export const TitleMap = ['蘇秦', '造紙', '武備', '武后', '邊疆', '元朝', '傳教'];

function CompatUrlRedirect() {
  const location = useLocation();
  const { units } = useContext(UnitContext);
  // console.log('CompatUrlRedirect', location.pathname);
  if ('/main.php' === location.pathname) {
    return <Redirect to={`/`}></Redirect>;
  } else if ('/subui.php' === location.pathname) {
    let id = parseInt((new URLSearchParams(location.search)).get('id') as string);
    let islandIndex = 1;

    if (units.length > 0) {
      console.log(units);
      islandIndex = units.map(u => u.lv1id).findIndex(x => x === id);
    } else {
      console.log('id');
      islandIndex = [4, 8, 10, 12, 36, 42, 30, 54, 48].findIndex(x => x === id);
    }

    islandIndex = -1 === islandIndex ? 1 : (1 + islandIndex);
    // console.log(location.pathname, islandIndex);
    return <Redirect to={`/island/${islandIndex}/main`}></Redirect>;
  }

  return <Redirect to="/"></Redirect>;
}

export default memo(CompatUrlRedirect);
