import { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Modal from '../../components/ui/Modal';
import SvgButton from '../../components/ui/SvgButton';
import { ArenaContext } from '../../utils/providers/ArenaProvider';
import { getPassedUnitCount, IconMapGet } from '../arena';
import PageArenaTitle from './arena-title';

interface PageArenaIconGetProps {
  // index: string,
  // onClick: () => void,
}

export default function PageArenaIconGet(props: PageArenaIconGetProps) {
  const [loaded, setLoaded] = useState(false);
  const { value } = useContext(ArenaContext);
  const [thisValue, setThisValue] = useState({
    count: 0,
    iconIndex: 1,
  });

  useEffect(() => {
    setLoaded(true);
    setThisValue({
      count: getPassedUnitCount(value),
      iconIndex: 1,
    })
  }, [value]);

  if (!loaded) {
    return <></>;
  }

  return (
    <Modal>
      <div className={`page-island-qna-score page-island-arena-score page-island-qna-icon-get dialog`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/arena/battle-popup-5.svg)` }}>
        <div className="dialog-content">
          <div className="page-island-qna-icon-get-content">
            <div className="page-island-qna-icon-get-content-title">
              <span>你已完成{thisValue.count}個歷史時期, 獲得以下頭像奬勵</span>
            </div>
            <div className="page-island-qna-icon-get-content-flags">
              {Object.entries(value.unitTopest).map(([unitId, topest]) => topest >= 16
                ? <div className="page-island-qna-icon-get-content-flag">
                  <img src={`${process.env.PUBLIC_URL}/assets/arena/battle-score-icon-${unitId}.svg`} alt="" />
                  <div>
                    <PageArenaTitle index={unitId} />
                  </div>
                </div>
                : null)}
            </div>
            <div className="page-island-qna-icon-get-content-icon">
              <img src={`${process.env.PUBLIC_URL}/assets/arena/battle-score-large-icon-${IconMapGet[thisValue.count] - 1}.svg`} alt="" />
            </div>
          </div>
          <Link to="/arena/index">
            <SvgButton prefix="/assets/arena/" src1="battle-button-enter.svg" src2="battle-button-enter-select.svg" alt="確定" />
          </Link>
        </div>
      </div>
    </Modal>
  )
}
