import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ModalLeaderBoardV4 from '../../pagesV4/components/ModalLeaderBoardV4';
import ModalNewsV4 from '../../pagesV4/components/ModalNewsV4';
import ModalPlatformInfoV4 from '../../pagesV4/components/ModalPlatformInfoV4';
import { AvatorIcons } from '../../pagesV4/components/ModalProfileAvatorSelectorV4';
import ModalProfileV4 from '../../pagesV4/components/ModalProfileV4';
import ModalSettingsV4 from '../../pagesV4/components/ModalSettingsV4';
import { extendsLeaderboardUser } from '../../pagesV4/constants';
import { getHeader, getNews, getRanking } from '../../utils/api';
import ModelHeader from '../../utils/models/joined/Header';
import LeaderboardUser from '../../utils/models/LeaderboardUser';
import News from '../../utils/models/News';
import { useAuthContext } from '../../utils/providers/AuthProvider';
import { useBgmContext } from '../../utils/providers/BgmProvider';
import { DevPortal } from "../../utils/providers/DevPortalProvider";
import { useUnitContext } from '../../utils/providers/UnitProvider';
import Modal from '../ui/Modal';
import './HeaderV4.scss';
import ScrollbarVertical from './ScrollbarVertical';

interface HeaderNewsProps {
  onNewsClick: (newsItem: News | null) => void,
}
const HeaderNews = ({ onNewsClick }: HeaderNewsProps) => {
  const [showingNews, setNews] = useState<Array<News>>([]);
  const refDiv = useRef<HTMLDivElement>(null);
  const [isNewDivHidden, setIsNewDivHidden] = useState(false);

  const modalShow = (newsItem: News) => {
    onNewsClick(newsItem);
  }

  useEffect(() => {
    const widthCheck = () => {
      setIsNewDivHidden(false);
      if (refDiv.current) {
        setIsNewDivHidden(refDiv.current.getBoundingClientRect().width < 80)
      }
    }
    window.addEventListener('resize', widthCheck);
    widthCheck();
    return () => {
      window.removeEventListener('resize', widthCheck);
    }
  }, [refDiv]);

  useEffect(() => {
    if (showingNews.length === 0) {
      getNews().then(n => {
        setNews([...n, ...n].slice(0, 2));
      });
    }
  }, [showingNews.length]);

  const onWrapperClick = () => {
    if (isNewDivHidden) {
      onNewsClick(null);
    }
  }

  return (<div className={`header-main-news ${isNewDivHidden ? 'header-main-news-short' : ''}`} onClick={() => onWrapperClick()}>
    <div ref={refDiv} className="news" style={({ '--line': showingNews.reduce((p, news) => p + news.name.length >= 19 ? 2 : 1, 0) * 2 } as React.CSSProperties)}>
      {[0, 1].map((i) =>
        <div className={`news-marquee news-marquee-${i}`} key={i}>
          {showingNews.map((newsItem, index) =>
            <div key={`${newsItem.id}-${index}`} className={`news-${1 + index}`} onClick={() => modalShow(newsItem)}>
              {0 === index
                ? <span>{newsItem.name}</span>
                : <span>{newsItem.name}</span>
              }
            </div>
          )}
        </div>
      )}
    </div>
    <div className="news-button">按此</div>
  </div>
  );
}

interface HeaderQuickLinksProps {
  onInfoClick: () => void,
  onSettingsClick: () => void,
  onRankClick: () => void,
  onBgMusicClick: () => void,
  onNewsClick: (newsItem: News | null) => void,
}
const HeaderQuickLinks = ({ onInfoClick, onSettingsClick, onRankClick, onBgMusicClick, onNewsClick, }: HeaderQuickLinksProps) => {
  const [show, setShow] = useState(false);
  const { state } = useBgmContext();
  const { units } = useUnitContext();
  const { logged } = useAuthContext();
  // console.log(units);
  console.log('HeaderQuickLinks', logged);
  return (
    <>
      <button className="header-quick-links-toggle" onClick={() => setShow(o => !o)}></button>
      {show && <Modal className="modal-container-no-bg">
        <div className="header-vertical_reel">
          <div className="header-vertical_reel-t"></div>
          <div className="header-vertical_reel-c">
            <ScrollbarVertical>
              <div className="links">
                <Link to={'/'}>自學課題</Link>
                {units.map((u, index) => {
                  return <Link key={u.id} to={`/island/${1 + index}/main`} className={`level-2`} aria-label={`index`}>{u.name}<br />{u.lv1Name}</Link>
                })}
                {[
                  { alias: 'treasure', link: '/treasure-v4', name: '奪寶奇兵', },
                  { alias: 'battle', link: '/arena', name: '歷史擂台', },
                  { alias: 'reading', link: '/reading-v4', name: '喜閱歷史', },
                  { alias: 'resource', link: '/resources-v4', name: '拉闊學習資源區', },
                  // { alias: 'teacher', link: '/teacher-v4', name: '教師專區', },
                ].map(item =>
                  <Link key={item.alias} to={item.link} aria-label={`index`}>{item.name}</Link>
                )}
                {logged && 'Teacher' === logged.role && [
                  { alias: 'teacher', link: '/teacher-v4', name: '教師專區', },
                ].map(item =>
                  <Link key={item.alias} to={item.link} aria-label={`index`}>{item.name}</Link>
                )}
                <button className={`header-buttons`} onClick={() => onNewsClick(null)}>新聞</button>
                <button className={`header-buttons`} onClick={onInfoClick}>關於本平台</button>
                <button className={`header-buttons`} onClick={onSettingsClick}>設定</button>
                <button className={`header-buttons`} onClick={onRankClick}>排名</button>
                <button className={`header-buttons`} onClick={onBgMusicClick}>{state.stopped ? "開啓背景音樂" : "關閉背景音樂"}</button>
              </div>
            </ScrollbarVertical>
          </div>
          <div className="header-vertical_reel-b"></div>
        </div>
      </Modal>}
    </>
  )
}

const HeaderTop20 = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState<LeaderboardUser[]>([]);
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (show) {
      if (0 === data.length) {
        setLoaded(false);
        getRanking('all').then(r => {
          let temp = extendsLeaderboardUser(r);
          setData(temp.slice(0, 20));
          setLoaded(true);
        });
      }
    }
  }, [show, data]);

  if ('/main-v4' !== location.pathname && '/main' !== location.pathname && '/' !== location.pathname) {
    return <></>;
  }

  return (
    <>
      <button className="header-top_20-toggle" onClick={() => setShow(o => !o)}>
        <div className="header-top_20-toggle-l"></div>
        <div className="header-top_20-toggle-c btn-c">全港首20排名</div>
        <div className="header-top_20-toggle-r"></div>
      </button>
      {show && <Modal className="modal-container-no-bg">
        <div className="header-vertical_reel header-vertical_reel-top_20">
          <div className="header-vertical_reel-t"></div>
          <div className="header-vertical_reel-c">
            <ScrollbarVertical>
              <div className="links">
                {loaded
                  ? data.map((leaderboardUser, index) => <span key={leaderboardUser.userID}>
                    {1 + index} {leaderboardUser.cname}<br />- {leaderboardUser.cSchoolName} ({leaderboardUser.classNo})
                  </span>)
                  : <>載入中...</>}
              </div>
            </ScrollbarVertical>
          </div>
          <div className="header-vertical_reel-b"></div>
        </div>
      </Modal>}
    </>
  )
}

interface HeaderAvatorProps {
  character: number
  onAvatorClick: () => void,
}
const HeaderAvator = ({ onAvatorClick, character }: HeaderAvatorProps) => {
  return (<div className="header-main-avator" onClick={onAvatorClick}>
    <img src={AvatorIcons[character]} tabIndex={1} alt="我的戶口" />
  </div>);
}

const HeaderV4 = () => {
  const { state, forceStop, forceStart } = useBgmContext();
  const location = useLocation();

  const [userInfo, setUserInfo] = useState<ModelHeader | null>(null);

  const [showModalNews, setShowModalNews] = useState(false);
  const [showModalNewsItem, setShowModalNewsItem] = useState<News | null>(null);
  const [showModalPlatformInfo, setShowModalPlatformInfo] = useState(false);
  const [showModalSettings, setShowModalSettings] = useState(false);
  const [showModalProfile, setShowModalProfile] = useState(false);
  const [showModalLeaderBoard, setShowModalLeaderBoard] = useState(false);

  const onModalNewsClose = () => {
    setShowModalNews(false);
  }
  const onModalPlatformInfoClose = () => {
    setShowModalPlatformInfo(false);
  }
  const onModalSettingsClose = () => {
    setShowModalSettings(false);
  }
  const onModalProfileClose = () => {
    setShowModalProfile(false);
  }
  const onModalLeaderBoardClose = () => {
    setShowModalLeaderBoard(false);
  }
  const onInfoClick = () => {
    setShowModalPlatformInfo(true);
  }
  const onRankClick = () => {
    setShowModalLeaderBoard(true);
  }
  const onSettingsClick = () => {
    setShowModalSettings(true);
  }
  const onBgMusicClick = () => {
    if (state.stopped) {
      forceStart();
    } else {
      forceStop();
    }
  }
  const onNewsClick = (newsItem: News | null) => {
    setShowModalNews(true);
    setShowModalNewsItem(newsItem);
  }

  const updateInfo = () => {
    getHeader().then(ui => {
      setUserInfo(ui);
      console.log('updateInfo');
    });
  };

  useEffect(() => {
    if ('/login-v4' === location.pathname || '/login' === location.pathname) {
    } else {
      updateInfo();
    }
  }, [location.pathname]);

  if ('/login-v4' === location.pathname || '/login' === location.pathname) {
    return <></>;
  }

  if (!userInfo) {
    return <></>;
  }

  return <header className={"v4"}>
    <HeaderQuickLinks onInfoClick={onInfoClick} onSettingsClick={onSettingsClick} onRankClick={onRankClick} onBgMusicClick={onBgMusicClick} onNewsClick={() => onNewsClick(null)} />
    <div className="header-main">
      <div className="header-main-l">
      </div>
      <div className="header-main-c">
        <HeaderAvator character={userInfo.userSettings.character} onAvatorClick={() => setShowModalProfile(true)}></HeaderAvator>
        <div className="header-main-name">{userInfo.cname}</div>
        {/* <div className="header-main-class" tabIndex={3} title={`班級: ${userInfo.classNo}`} >{`班級: ${userInfo.classNo}`}</div> */}
        <div className="header-main-score" tabIndex={4} title={`${userInfo._.viewUserTotalStars} 星`}>{`${userInfo._.viewUserTotalStars}`}</div>
        <HeaderNews onNewsClick={(newsItem: News | null) => onNewsClick(newsItem)} />
      </div>
      <div className="header-main-r">
      </div>
    </div>
    <div className="header-buttons">
      <button className="header-buttons-button header-buttons-button-info" onClick={onInfoClick}>
        <div className="header-buttons-button-l"></div>
        <div className="header-buttons-button-c"></div>
        <div className="header-buttons-button-r"></div>
        <div className="header-buttons-button-icon"></div>
      </button>
      <button className="header-buttons-button header-buttons-button-settings" onClick={onSettingsClick}>
        <div className="header-buttons-button-l"></div>
        <div className="header-buttons-button-c"></div>
        <div className="header-buttons-button-r"></div>
        <div className="header-buttons-button-icon"></div>
      </button>
      <button className="header-buttons-button header-buttons-button-rank" onClick={onRankClick}>
        <div className="header-buttons-button-l"></div>
        <div className="header-buttons-button-c"></div>
        <div className="header-buttons-button-r"></div>
        <div className="header-buttons-button-icon"></div>
      </button>
      <button className={`header-buttons-button ${state.stopped ? 'header-buttons-button-bg-music' : 'header-buttons-button-bg-music-on'}`} onClick={onBgMusicClick}>
        <div className="header-buttons-button-l"></div>
        <div className="header-buttons-button-c"></div>
        <div className="header-buttons-button-r"></div>
        <div className="header-buttons-button-icon"></div>
      </button>
    </div>
    <div className="header-top_20">
      <HeaderTop20 />
    </div>
    {showModalNews && <ModalNewsV4 onClose={onModalNewsClose} newsItem={showModalNewsItem} />}
    {showModalPlatformInfo && <ModalPlatformInfoV4 onClose={onModalPlatformInfoClose} />}
    {showModalSettings && <ModalSettingsV4 onClose={onModalSettingsClose} />}
    {showModalProfile && <ModalProfileV4 onClose={onModalProfileClose} onAvatorUpdate={() => updateInfo()} />}
    {showModalLeaderBoard && <ModalLeaderBoardV4 onClose={onModalLeaderBoardClose} />}
    <DevPortal>
      ww: {window.innerWidth}
      wih: {window.innerHeight}
      woh: {window.outerHeight}
      ch: {document.documentElement.clientHeight}
      <button onClick={() => setShowModalSettings(true)}>Setting</button>
      <button onClick={() => setShowModalProfile(true)}>Profile</button>
      <button onClick={() => setShowModalLeaderBoard(true)}>Leader</button>
      <button onClick={() => document.body.requestFullscreen()}>FS</button>
    </DevPortal>
  </header>;
}

export default HeaderV4;
