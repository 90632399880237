import { memo, ReactNode } from 'react';
import SvgButton from './SvgButton';
import Modal from './Modal';
import ScrollbarVertical from '../project-ui/ScrollbarVertical';

interface ModalDialogProps {
  title: string,
  alias: string,
  wrapperClassName: string,
  children: ReactNode,
  showClose: boolean,
  onClose?: () => void,
}

function ModalDialog(props: ModalDialogProps) {
  return (
    <Modal>
      <div className={`dialog ${props.wrapperClassName}`} role="dialog" aria-labelledby={`modal-${props.alias}-title`} aria-describedby={`modal-${props.alias}-desc`} style={{ backgroundImage: "url(/assets/header/news-dialog-01.svg)" }}>
        <h3 className="dialog-title" id={`modal-${props.alias}-title`}>{props.title}</h3>
        {props.showClose ? <button className="dialog-btn-close" aria-label="關閉" onClick={() => props && props.onClose && props.onClose()}><SvgButton prefix="/assets/settings/" src1="setting-button-close.svg" src2="setting-button-close-select.svg" alt="關閉"></SvgButton></button> : null}
        <div className="dialog-content resizable-text" id={`modal-${props.alias}-desc`}>
          <div style={{ height: 620 }}>
            <ScrollbarVertical>
              {props.children}
            </ScrollbarVertical>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default memo(ModalDialog);
