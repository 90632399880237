import React, { useEffect, useState } from 'react';
import ScrollbarVertical from '../../components/project-ui/ScrollbarVertical';
import { getAboutUs } from '../../utils/api';
import ModalReel from './ModalReel';
import "./ModalAboutUs.scss";

interface ModalAboutUsProps {
  onClose?: () => void,
}

const ModalAboutUs = ({ onClose }: ModalAboutUsProps) => {
  const [aboutUs, setAboutUs] = useState({ content: '' });

  useEffect(() => {
    getAboutUs().then(c => setAboutUs(c));
  }, []);

  if ('' === aboutUs.content) {
    return <></>;
  }

  return (
    <ModalReel className="modal-about_us" open={true} onClose={onClose} theme="green">
      <div className="modal-about_us-body">
        <ScrollbarVertical>
          <div dangerouslySetInnerHTML={{ __html: aboutUs.content }}></div>
        </ScrollbarVertical>
      </div>
      {/* <div className="modal-settings-dingding-1"></div> */}
      <div className="modal-title">
        <div className="modal-title-l"></div>
        <div className="modal-title-c">關於本站</div>
        <div className="modal-title-r"></div>
      </div>
    </ModalReel>)
}

export default ModalAboutUs;
