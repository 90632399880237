import { CSSProperties, useState } from 'react';

function SvgButton(props: { className?: string, style?: CSSProperties, prefix: string, src1: string, src2: string, alt: string }) {
  const [src, setSrc] = useState(props.prefix + props.src1);

  return (
    <img className={props.className} style={props.style} src={src} alt={props.alt}
      onMouseEnter={() => setSrc(props.prefix + props.src2)}
      onMouseLeave={() => setSrc(props.prefix + props.src1)}
    />
  )
}

export default (SvgButton);
