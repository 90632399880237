import React, { useEffect, useState } from 'react';
import News from '../../utils/models/News';
import { useNewsContext } from '../../utils/providers/NewsProvider';
import "./ModalNewsV4.scss";
import ModalReel from './ModalReel';

interface ModalProfileV4Props {
  onClose?: () => void,
  newsItem: News | null,
}

const ModalProfileV4 = ({ onClose, newsItem }: ModalProfileV4Props) => {
  const [showingNewsItem, setShowingNewsItem] = useState<News | null>(newsItem);
  const { news } = useNewsContext();

  useEffect(() => {
    if (null === showingNewsItem && news.length >= 1) {
      setShowingNewsItem(news[0])
    }
  }, [showingNewsItem, news]);
  // console.log(news);

  return (
    <ModalReel className="modal-news" open={true} onClose={onClose}>
      {null !== showingNewsItem
        ? <>
          <h4 dangerouslySetInnerHTML={{ __html: showingNewsItem.name }}></h4>
          <div dangerouslySetInnerHTML={{ __html: showingNewsItem.abstract }}></div>
        </>
        : null
      }
    </ModalReel>)
}

export default ModalProfileV4;
