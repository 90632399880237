import React, { createContext, ReactNode, useContext } from 'react';
import { createPortal } from 'react-dom';
import AppTemplateProvider from './AppTemplateProvider';
import AuthProvider from './AuthProvider';
import BackgroundProvider from './BackgroundProvider';
import BgmProvider from './BgmProvider';
import DevPortalProvider from './DevPortalProvider';
import NewsProvider from './NewsProvider';
import UnitProvider from './UnitProvider';

interface Context {
  dateTimestamp: string,
}

const DEFAULTCONTEXT: Context = {
  dateTimestamp: '',
};

const AppConfigContext = createContext<Context>(DEFAULTCONTEXT)

export const useAppConfigContext = () => useContext(AppConfigContext);

interface RootPortalProps {
  children: ReactNode
}
export const RootPortal = ({ children }: RootPortalProps) => {
  return document.getElementById('root') && createPortal(children, document.getElementById('root') as HTMLDivElement);
}

interface AppConfigProviderProps {
  children: ReactNode,
  dateTimestamp: string,
}

const AppConfigProvider = ({ children, dateTimestamp }: AppConfigProviderProps) => {
  ('development' === process.env.NODE_ENV) && console.log('[b4-return] AppConfigProvider', window.location.hash);
  return (
    <AppConfigContext.Provider value={{ dateTimestamp }}>
      <DevPortalProvider>
        <AuthProvider>
          <NewsProvider>
            <UnitProvider>
              <BackgroundProvider>
                <AppTemplateProvider>
                  <BgmProvider>
                    {children}
                  </BgmProvider>
                </AppTemplateProvider>
              </BackgroundProvider>
            </UnitProvider>
          </NewsProvider>
        </AuthProvider>
      </DevPortalProvider>
    </AppConfigContext.Provider>
  )
}

export default AppConfigProvider;
