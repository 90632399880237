import { memo } from 'react';
import SvgButton from '../../../components/ui/SvgButton';
import Modal from '../../ui/Modal';
import ScrollbarVertical from '../ScrollbarVertical';
import "./PlatformIntro.scss";

interface PlatformIntroProps {
  onClose: () => void,
}

function PlatformIntro(props: PlatformIntroProps) {
  return (
    <Modal>
      <div className="dialog modal-platform-intro" aria-label="想 • 創 • 中國歷史——學生自學平台" aria-describedby={`modal-platform-intro-desc`} style={{ backgroundImage: "url(/assets/header/news-dialog-01.svg)" }}>
        <button className="dialog-btn-close" aria-label="關閉" onClick={() => props.onClose()}><SvgButton prefix="/assets/settings/" src1="setting-button-close.svg" src2="setting-button-close-select.svg" alt="關閉"></SvgButton></button>
        <div className="dialog-content resizable-text" id="modal-platform-intro-desc">
          <img src="/logo.svg" style={{
            width: '35%',
            display: 'block',
            clear: 'both',
            margin: '0 auto 2%',
          }} alt="想 • 創 • 中國歷史——學生自學平台" />
          {/* id={`modal-${props.alias}-desc`}
          <svg className="platform-intro-edb" viewBox="0 0 867 402">
            <text x="10" y="60" className="shadow">教育局</text>
            <text x="10" y="60" className="text">教育局</text>
          </svg>
           */}
          <div style={{ height: 400 }}>
            <ScrollbarVertical>
              {/* <p>中國歷史科修訂課程（中一至中三）於2020/21學年在全港學校中一級開始逐級實施。為培養學生對中國歷史的興趣，以及促進自主學習，教育局為全港初中學生建立「想‧創‧中國歷史 - 學生自學平台」。</p> */}
              {/* <p>自學平台目前設有中一級和中二級延伸課題的自學單元，由歷史專家、學者、資深教育人士合作編寫及設計，各單元設有動畫、遊戲及小測驗，以活潑有趣的學習內容，鼓勵學生自學中國歷史。</p> */}
              {/* <p>除了上述的自學單元外，平台設有「歷史資源區」，為初中學生提供與修訂課程相關的多元化學習材料，如中國歷史閱讀篇章和動畫短片等，豐富學生的歷史知識。</p> */}
              {/* <p>此外，平台亦新增「歷史擂台」專區，提供涵蓋初中中國歷史科修訂課程的評估題目，學生可因應個人興趣和學習進程，訂立自主學習的目標和計劃，完成自學單元及「歷史擂台」題目。教師可根據學生參與上述自學活動的進程，了解其學習表現，給予適時的回饋，促進學習的評估。</p> */}
              <p>為配合中國歷史科修訂課程（中一至中三）的推行，並促進學生自主學習，教育局為全港初中學生建立「想・創・中國歷史  —學生自學平台」。平台自學單元配合修訂課程中一至中三級的選教或延伸課題，以動畫、遊戲、多媒體片段等方式，鼓勵學生進行自主學習。</p>
              <p>此外，自學平台設有「歷史擂台」專區，提供涵蓋整個初中課程的評估題目，學生可因應個人興趣和學習進程，訂立自主學習的目標和計劃，完成自學單元及「歷史擂台」題目。</p>
              <p>為進一步鼓勵學生善用自學平台進行自主學習，平台將會推出「奪寶奇兵」全港初中學生自學奬勵計劃，將學歷史、賞文物結合為刺激的學習活動。</p>
            </ScrollbarVertical>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default memo(PlatformIntro);
