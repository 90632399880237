import { memo } from 'react';
import ModelNews from '../../../utils/models/News';
import ModalDialog from '../../ui/ModalDialog';
import "./News.scss";

interface NewsProps {
  news: ModelNews,
  onClose: () => void,
}

function News(props: NewsProps) {
  return (
    <ModalDialog title="通知" wrapperClassName="modal-news" alias="news" showClose={true} onClose={props.onClose}>
      <h4 dangerouslySetInnerHTML={{ __html: props.news.name }}></h4>
      <div dangerouslySetInnerHTML={{ __html: props.news.abstract }}></div>
    </ModalDialog>
  )
}

export default memo(News);
