import React, { memo, useEffect, useState } from 'react';
import { getPassedUnitCount, IconMap } from '../../../pages/arena';
import { getMyArenaResult } from '../../../utils/api';
import ModelHeader from '../../../utils/models/joined/Header';
import MyUnitResult from '../../../utils/models/joined/MyUnitResult';
import Modal from '../../ui/Modal';
import SvgButton from '../../ui/SvgButton';
import ScrollbarVertical from '../ScrollbarVertical';

interface ProfileIconSelectProps {
  userInfo: ModelHeader,
  unitMarks: Array<MyUnitResult>,
  handleClick: (i: number) => void,
  onClose?: () => void,
}

const UnitIdToImagesId: { [unitId: number]: { alias: string, imgs: Array<number> } } = {
  4: { alias: '史前至夏商周', imgs: [27,] },
  8: { alias: '秦漢', imgs: [25, 26,] },
  10: { alias: '三國兩晉南北朝', imgs: [28, 29,] },
  12: { alias: '隋唐', imgs: [23, 24,] },
  36: { alias: '宋朝', imgs: [31, 33, 34] },
  42: { alias: '元朝', imgs: [35, 36, 37, 38, 39, 40,] },
  30: { alias: '明朝', imgs: [30, 32] },
  48: { alias: '中華人民共和國', imgs: [] },
  54: { alias: '中華民國', imgs: [] },
}

function ProfileIconSelect(props: ProfileIconSelectProps) {
  const { userInfo, unitMarks } = props;
  const [showModal, setShowModal] = useState(false);
  const [arenaLoaded, setArenaLoaded] = useState(false);
  const [iconIndex, setIconIndex] = useState(0 === userInfo.userSettings.character ? 17 : userInfo.userSettings.character);
  const [disabledMap, setDisabledMap] = useState<Array<boolean>>([
    false, ...Array(7).fill(0).map(() => true),
    false, ...Array(7).fill(0).map(() => true),
    ...Array(6).fill(0).map(() => false),
    ...Array(18).fill(0).map(() => true),
  ]);

  useEffect(() => {
    getMyArenaResult().then((c) => {
      // console.log(getPassedUnitCount(c), IconMap[getPassedUnitCount(c)]);

      setDisabledMap(o => {
        let temp = o.slice(0);
        unitMarks.forEach(({ totalStar, unit_id }) => {
          if (parseInt(totalStar) >= 7 && UnitIdToImagesId[unit_id] && UnitIdToImagesId[unit_id].imgs) {
            UnitIdToImagesId[unit_id].imgs.forEach(imgIndex => {
              temp[imgIndex - 1] = false;
            })
          }
        });
        for(let i = 0; i < 8; ++i) {
          if (i < IconMap[getPassedUnitCount(c)] - 1) {
            temp[i] = false;
            temp[8 + i] = false;
          }
        }
        return temp;
      })
      setArenaLoaded(true);
    });
  }, [unitMarks]);

  const handleClick = (imgIndex: number) => {
    // let passed = false;
    // if (passed) {
    setIconIndex(1 + imgIndex);
    props.handleClick(1 + imgIndex);
    // }
  }

  const onOpen = () => {
    setShowModal(true);
  }

  const onClose = () => {
    setShowModal(false);
  }
  // console.log(userInfo, unitMarks, disabledMap);
  if (null === userInfo || false === arenaLoaded) {
    return null;
  }

  return (
    <>
      <img onClick={() => onOpen()} src={`/assets/profile/account-large-icon/account-large-icon${iconIndex}.svg`} alt="" />
      {showModal
        ? <Modal className="modal-profile-icon-select">
          <div className="dialog" style={{ backgroundImage: "url(/assets/profile/account-greybg.svg)" }}>
            <button className="dialog-btn-close" aria-label="關閉" onClick={() => onClose()}><SvgButton prefix="/assets/profile/" src1="account-button-close.svg" src2="account-button-close-select.svg" alt="關閉"></SvgButton></button>
            <div className="wrapper">
              <ScrollbarVertical>
                <div className="image-grid">
                  {Array(40).fill(0).map((_, i) =>
                    <img className={disabledMap[i] ? `disabled` : ''} key={i} onClick={() => handleClick(i)} src={`/assets/profile/account-small-icon/account-small-icon${1 + i}.svg`} alt={disabledMap[i] ? `` : ''} />
                  )}
                </div>
              </ScrollbarVertical>
            </div>
          </div>
        </Modal>
        : null}
    </>
  )
}

export default memo(ProfileIconSelect);
