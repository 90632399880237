import { memo, useContext, useEffect, useRef, useState } from 'react';
// import { Scrollbar } from "react-scrollbars-custom";
import Modal from '../../components/ui/Modal';
import SvgButton from '../../components/ui/SvgButton';
import { getHeader, putUserSettings, LOGOUT_URL } from '../../utils/api';
import ModelHeader from '../../utils/models/joined/Header';
import { AuthContext } from '../../utils/providers/AuthProvider';
import AboutUs from './about-us';
import './main.scss';
import TechnicalSupport from './technical-support';
// import ScrollbarHorizontal, { ScrollbarHorizontalInstance } from '../../components/project-ui/ScrollbarHorizontal';

interface PageSettingsMainProps {
  onClose: () => void
}

function PageSettingsMain(props: PageSettingsMainProps) {
  const { signOut } = useContext(AuthContext);
  // const scrollbar = useRef<ScrollbarHorizontalInstance | null>(null);
  const svgBar = useRef<SVGSVGElement | null>(null);
  const [modalAboutUs, setModalAboutUs] = useState(false);
  const [modalTechnicalSupport, setModalTechnicalSupport] = useState(false);
  // 0 ~ 100
  const [volumePercent, setVolumePercent] = useState(0);

  const [userInfo, setUserInfo] = useState<ModelHeader | null>(null);

  const getCurrentPercent = () => {
    // if (scrollbar && scrollbar.current) {
    //   let scrollState = scrollbar.current.getScrollState();
    //   let percent = Math.round(100 * scrollState.scrollLeft / (scrollState.contentScrollWidth - scrollState.clientWidth));
    //   // console.log('getCurrentPercent', percent, scrollState);
    //
    //   return percent;
    // }
    console.log(volumePercent);
    return volumePercent / 100;
  }

  const moveScrollbar = (v: number) => {
    // if (scrollbar && scrollbar.current) {
    //   let scrollState = scrollbar.current.getScrollState();
    //   let left = v / 100 * (scrollState.contentScrollWidth - scrollState.clientWidth)
    //   // console.log(v, left, scrollbar.current, scrollbar.current.scrollTo);
    //   scrollbar.current.scrollTo(left, 0);
    // }
    setVolumePercent(v * 100);
  }

  const volumeUp = () => {
    let v = (getCurrentPercent() + .05);
    v = v >= 1 ? 1 : v;
    // console.log(v);
    putUserSettings('volume', v).then(() => {
      (document.querySelector('#bgAudio') as HTMLAudioElement).volume = v;
    });
    moveScrollbar(v);
  }

  const volumeDown = () => {
    let v = (getCurrentPercent() - .05);
    v = v <= 0 ? 0 : v;
    // console.log(v);
    putUserSettings('volume', v).then(() => {
      (document.querySelector('#bgAudio') as HTMLAudioElement).volume = v;
    });
    moveScrollbar(v);
  }

  // const volumeMove = (e: any) => {
  //   // console.log(getCurrentPercent());
  //   let v = getCurrentPercent();
  //   // console.log(v);
  //   putUserSettings('volume', v).then(() => {
  //     (document.querySelector('#bgAudio') as HTMLAudioElement).volume = v / 100;
  //   });
  // }

  const doLogout = () => {
    signOut(() => {
      //authSignOut().then(() => {
      window.location.href = `${LOGOUT_URL}?finish_uri=${encodeURIComponent(window.location.href)}`;
      //});
    })
  }
    const svgDebugger: HTMLDivElement = document.querySelector('#svgDebugger') as HTMLDivElement;
    const downEvent = (e: { alias: string, width: number, target: SVGSVGElement, offsetX: number, offsetY: number }) => {
      // console.log(e);
      // let width = (e.target.parentElement as HTMLDivElement).offsetWidth;
      let offsetX = e.offsetX - (e.width / 46 * 3.5);
      let newVolume = offsetX / (e.width / 46 * 40);
      newVolume = Math.min(1, Math.max(0, newVolume));

      putUserSettings('volume', newVolume).then(() => {
        (document.querySelector('#bgAudio') as HTMLAudioElement).volume = newVolume;
      });
      setVolumePercent(Math.round(newVolume * 100));

      if (svgDebugger) {
        svgDebugger.innerText = JSON.stringify({
          // pageX: e.pageX,
          // clientX: e.clientX,
          // rect: (e.target as SVGSVGElement).getBoundingClientRect(),
          alias: e.alias,
          width: e.width,
          offsetX,
          newVolume,
          offsetXRaw: e.offsetX,
          offsetXMinus: e.offsetX - 3.5,
          offsetXPercent: Math.round((e.offsetX - 3.5) / 40 * 100),
          // screenX: e.screenX,
          // movementX: e.movementX,
        }, null, 2);
      }
    }
    let isMouseDown = false;
    const mouseleave = () => isMouseDown = false
    const mousedown = () => isMouseDown = true
    const mousemove = (e: MouseEvent) => {
      // console.log(isMouseDown);
      if (isMouseDown) {
        downEvent({
          alias: 'mousemove',
          target: e.target as SVGSVGElement,
          width: ((e.target as SVGSVGElement).parentElement as HTMLDivElement).offsetWidth,
          offsetX: e.offsetX,
          offsetY: e.offsetY,
        });
        e.preventDefault();
        e.stopPropagation();
      }
    }
    const mouseup = (e: MouseEvent) => {
      isMouseDown = false;
      e.preventDefault();
      e.stopPropagation();
      downEvent({
        alias: 'mouseup',
        target: e.target as SVGSVGElement,
        width: ((e.target as SVGSVGElement).parentElement as HTMLDivElement).offsetWidth,
        offsetX: e.offsetX,
        offsetY: e.offsetY,
      });
    };

    let scale = 1;
    if ((window.innerWidth / window.innerHeight) > (1920 / 1080)) {
      scale = window.innerHeight / 1080;
    } else {
      scale = window.innerWidth / 1920;
    }
    const touchmove = (e: TouchEvent) => {
      e.preventDefault();
      e.stopPropagation();
      // console.log((e.target as SVGSVGElement).getBoundingClientRect().width, scale);
      downEvent({
        alias: 'touchmove',
        target: e.target as SVGSVGElement,
        // width: (e.target as SVGSVGElement).getBoundingClientRect().width / scale,
        width: ((e.target as SVGSVGElement).parentElement as HTMLDivElement).offsetWidth * scale,
        offsetX: e.touches[0].pageX - (e.target as SVGSVGElement).getBoundingClientRect().left,
        offsetY: e.touches[0].pageY - (e.target as SVGSVGElement).getBoundingClientRect().top,
      })
    };

  const refSvgBar = (e: SVGSVGElement | null) => {
    // console.log(e);
    if (svgBar.current) {
      svgBar.current.removeEventListener('mouseup', mouseup);
      svgBar.current.removeEventListener('mousemove', mousemove);
      svgBar.current.removeEventListener('mousedown', mousedown);
      svgBar.current.removeEventListener('mouseleave', mouseleave);
      svgBar.current.removeEventListener('touchstart', touchmove);
      svgBar.current.removeEventListener('touchmove', touchmove);
    }

    if (e) {
      svgBar.current = e;
      // if (svgBar.current) {
      // console.log('svgBar.current', svgBar.current);

      svgBar.current.addEventListener('mouseup', mouseup);
      svgBar.current.addEventListener('mousemove', mousemove);
      svgBar.current.addEventListener('mousedown', mousedown);
      svgBar.current.addEventListener('mouseleave', mouseleave);
      svgBar.current.addEventListener('touchstart', touchmove);
      svgBar.current.addEventListener('touchmove', touchmove);
      // return () => {
      //   if (svgBar.current) {
      //     svgBar.current.removeEventListener('mouseup', mouseup);
      //     svgBar.current.removeEventListener('mousemove', mousemove);
      //     svgBar.current.removeEventListener('mousedown', mousedown);
      //     svgBar.current.removeEventListener('mouseleave', mouseleave);
      //     svgBar.current.removeEventListener('touchstart', touchmove);
      //     svgBar.current.removeEventListener('touchmove', touchmove);
      //   }
      // }

    }
  }
  // }, [svgBar.current]);

  useEffect(() => {
    getHeader().then(x => {
      setUserInfo(x);
      setVolumePercent(x.userSettings.volume);
    });
  }, []);

  if (null === userInfo) {
    return <></>;
  }

  return (
    <div className="page-settings-main dialog" style={{ backgroundImage: "url(/assets/settings/setting-bubble-sound.svg)" }}>
      <h3 className="dialog-title">設定</h3>
      <button className="dialog-btn-close" aria-label="關閉" onClick={() => props.onClose()}><SvgButton prefix="/assets/settings/" src1="setting-button-close.svg" src2="setting-button-close-select.svg" alt="關閉"></SvgButton></button>

      <div className="volume">
        {/* <div className="label">音效</div> */}
        <div className="volume-dragbar">
          <div className="volume-dragbar-left">
            <img src="/assets/settings/sound_icon.svg" alt="" />
            <img onClick={volumeDown} src="/assets/settings/sound_turnoff-01.svg" alt="" />
          </div>
          {/* <div className="volume-dragbar-left"><img onClick={volumeDown} src="/assets/settings/setting-bubble-arrow-1.svg" alt="" /></div> */}
          <div className="volume-dragbar-bar">
            {/* <Scrollbar style={{ width: '666px', height: '132px' }}
              permanentTrackX={true}
              onScrollStop={volumeMove}
              ref={(s: any) => {
                scrollbar.current = s;

                setTimeout(() => {
                  if (scrollbar.current) {
                    let scrollState = scrollbar.current.getScrollState();
                    let left = userInfo.userSettings.volume / 100 * (scrollState.contentScrollWidth - scrollState.clientWidth)
                    scrollbar.current.scrollTo(left, 0);
                  }
                }, 300);
              }}
              elementRef={(scrollbarRef: any) => {
                console.log(scrollbarRef);
              }}
              wrapperProps={{
                renderer: (props: any) => {
                  const { elementRef, ...restProps } = props;
                  return <span {...restProps} ref={elementRef} className="volume-dragbar-bar-scrollbar-wrapper" style={{ ...restProps.style, backgroundImage: 'url(/assets/settings/setting-bar-1.svg)' }} />;
                }
              }}
              trackXProps={{
                renderer: (props: any) => {
                  const { elementRef, ...restProps } = props;
                  return <span {...restProps} ref={elementRef} className="volume-dragbar-bar-scrollbar-track-x" />;
                }
              }}
              thumbXProps={{
                renderer: (props: any) => {
                  const { elementRef, ...restProps } = props;
                  return <span {...restProps} ref={elementRef} className="volume-dragbar-bar-scrollbar-thumb-x" style={{ backgroundImage: "url(/assets/settings/setting-bar-2.svg)" }} />;
                }
              }}
            > */}
            <svg ref={refSvgBar} viewBox="0 0 46 7" style={{
              height: '100%',
              // background: 'red',
            }}>
              {/* <line x1="3.5" y1="3.5" x2={3.5 + 40} y2="3.5" stroke="#CC3" strokeLinecap="round" strokeWidth="3" /> */}
              <rect stroke="#C37700" strokeWidth=".5" fill="transparent" width="42.75" height="2.75" rx="1.5" x="2.125" y="2.125"></rect>
              <line x1="3.5" y1="3.5" x2={3.5 + 40 * (volumePercent / 100)} y2="3.5" stroke="#fcbf30" strokeLinecap="round" strokeWidth="3" />
            </svg>
            {/* <pre id="svgDebugger" style={{
              pointerEvents: 'none', userSelect: 'none', position: 'absolute', fontSize: 24,
              zIndex: 200,
              background: 'rgba(255,0,0,.5)',
            }}>1234</pre> */}
            {/* <ScrollbarHorizontal ref={scrollbar} onScrollStop={volumeMove}>
              <div style={{ width: '200%', height: '5px' }}>
              </div>
            </ScrollbarHorizontal> */}
            {/* </Scrollbar> */}
          </div>
          <div className="volume-dragbar-right">
            {/* <img src="/assets/settings/sound_icon.svg" alt="" /> */}
            <img onClick={volumeUp} src="/assets/settings/sound_loud-01.svg" alt="" />
            {/* <img onClick={volumeUp} src="/assets/settings/setting-bubble-arrow-2.svg" alt="" /> */}
          </div>
        </div>
      </div>

      {modalAboutUs ? <Modal><AboutUs onClose={() => setModalAboutUs(false)} /></Modal> : null}
      {modalTechnicalSupport ? <Modal><TechnicalSupport onClose={() => setModalTechnicalSupport(false)} /></Modal> : null}

      <div className="buttons">
        <button onClick={() => setModalTechnicalSupport(true)} aria-label="技術支援"><SvgButton prefix="/assets/settings/" src1="setting-button-support.svg" src2="setting-button-support-select.svg" alt="技術支援"></SvgButton></button>
        <button onClick={() => setModalAboutUs(true)} aria-label="關於本站"><SvgButton prefix="/assets/settings/" src1="setting-button-about.svg" src2="setting-button-about-select.svg" alt="關於本站"></SvgButton></button>
        <button onClick={() => doLogout()} aria-label="登出"><SvgButton prefix="/assets/settings/" src1="setting-button-logout.svg" src2="setting-button-logout-select.svg" alt="登出"></SvgButton></button>
      </div>
    </div >
  )
}

export default memo(PageSettingsMain);
