import React, { CSSProperties } from 'react';
import Lottie, { LottieProps, Options, } from 'react-lottie';
import { AnimationItem } from 'lottie-web';
import preval from 'preval.macro';

interface AnimationDataJson {
  // Version string X.Y.Z
  v: string,
  // width x height
  w: number,
  h: number,
  //
  assets: Array<{
    id: string,
    // Filename
    p: string,
    // Folder
    u: string,
    w: number,
    h: number,
    //
    e: number,
  }>
}

const dateTimeStamp = preval`module.exports = Date.now();`

// type AnimationData = any;

// export const MakeIcon = (jsonName: string, imgFolderName: string) => {
//   let animationData = requireAnimationData(jsonName, imgFolderName);
//   return (props: iProps): React.FunctionComponent => {
//     const lottieDiv = useRef(null);
//
//     const enter = () => {
//       if (null !== lottieDiv.current) {
//         // @ts-ignore
//         (lottieDiv.current as LottieDiv).anim.playSegments([120, 240], true);
//       }
//     }
//     const leave = () => {
//       if (null !== lottieDiv.current) {
//         // @ts-ignore
//         (lottieDiv.current as LottieDiv).anim.goToAndStop(0);
//       }
//     }
//
//     return (
//       <div className={`lottie-svg ${props.className ?? ''}`} onMouseEnter={enter} onMouseLeave={leave}>
//         <Lottie options={{ ...defaultOptions, animationData }} ref={lottieDiv} />
//       </div>
//     );
//   }
// }

export interface LottieDiv extends HTMLDivElement {
  anim: AnimationItem,
}

export function requireAnimationData(jsonName: string, imgFolderName: string) {
  let animationData: AnimationDataJson = require(`./json/${jsonName}.json`)
  // console.log(jsonName, imgFolderName, animationData);
  animationData.assets = animationData.assets.map((a: any) => ({
    ...a,
    p: a.p + (a.p && -1 !== a.p.indexOf('data:image') ? '' : (a.p && -1 === a.p.indexOf('?') ? ('?d=' + dateTimeStamp) : '&_repeated=1')),
    u: `/assets/icons/${imgFolderName}/`
  }));
  return animationData;
}

export const defaultOptions: Omit<Options, 'animationData'> = {
  loop: false,
  autoplay: false,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid meet'
  }
};

export const defaultProps: Partial<LottieProps> = {
  ariaRole: 'image',
}

export interface iProps {
  className?: any,
  style?: CSSProperties,
  title?: string,
}

export interface ExtendsIconProps extends Omit<LottieProps, 'options'> {
  loop?: boolean,
  autoplay?: boolean,

  tapToggle?: boolean,

  options?: {
    loop: boolean,
    autoplay: boolean,
    animationData: {},
  },

  style?: React.CSSProperties;
  onRefLottie?: (ref: any) => void;
}

export interface BaseIconProps extends Partial<LottieProps> {
  animationData: any,
  path: string,
}

export const DEFAULT_PROPS: LottieProps = {
  options: {
    loop: false,
    autoplay: false,
    animationData: {},
  },
}

export function processProps(props: ExtendsIconProps, folder: string, animationData: any) {
  let myProps = Object.assign(DEFAULT_PROPS, props);
  myProps.options.animationData = {
    ...animationData,
    assets: animationData.assets.map((a: any) => ({ ...a, u: `assets/icons/${folder}/` })),
  };
  return myProps;
}

export function fakeProps(props: ExtendsIconProps): any {
  return (props);
}

export default function makeIcon(Component: React.ComponentType<ExtendsIconProps>, folderName: string, animationData: unknown) {
  console.log('makeIcon', folderName, animationData);
  return (props: any) => {
    let myProps = processProps(props, folderName, animationData);
    console.log('makeIcon', myProps, props, folderName, animationData);
    // const [isFocused, setIsFocused] = React.useState(false);
    // let onRefLottie = (ref: any) => {
    //   console.log('onRefLottie', ref);
    // }
    // const enter = () => {
    //   setIsFocused(true);
    // }
    // const leave = () => {
    //   setIsFocused(false);
    // }
    // <div onMouseEnter={enter} onMouseLeave={leave}>
    // </div>
    return (
      <Lottie {...myProps} />
    );
  }
}

// const TestHocIcon = (props: {a: number, b: number }) => {
//   return (<p>{props.a} - {props.b}</p>);
// }

// export default makeIcon(TestHocIcon);

// export default function BaseIcon(props: BaseIconProps) {
//   const myProps = Object.assign(DEFAULT_PROPS, props);
//   myProps.options.animationData = {
//     ...props.animationData,
//     assets: props.animationData.assets.map((a: any) => ({ ...a, u: `assets/icons/${props.path}` })),
//   };
//   return (<Lottie {...myProps} />);
// }
