import React from 'react';
import ScrollbarVertical from '../../components/project-ui/ScrollbarVertical';
import "./ModalPlatformInfoV4.scss";
import ModalReel from './ModalReel';
import logo from '../../assets-v4/20230706_main/mainpage_logo.png';

interface ModalProfileV4Props {
  onClose?: () => void,
}

const ModalProfileV4 = ({ onClose }: ModalProfileV4Props) => {
  return (
    <ModalReel className="modal-platform-info" open={true} onClose={onClose}>
      <div className="dialog-content resizable-text resizable-text-normal" id="modal-platform-intro-desc" style={{ height: '100%' }}>
        <ScrollbarVertical>
          <img src={logo} style={{
            width: '30%',
            display: 'block',
            clear: 'both',
            margin: '0 auto 2%',
          }} alt="想 • 創 • 中國歷史——學生自學平台" />
          <p>為配合中國歷史科課程（中一至中三），教育局為全港初中學生建立「想‧創‧中國歷史——學生自學平台」。平台設有不同的活動專區，旨在拉闊學生的學習視野，促進自主學習。平台亦有教師專區，以支援及促進中國歷史教學。</p>
          <p>各單元設有動畫、小遊戲、冷知識和互動問答，由歷史學者、資深教育工作者合作編寫及設計。學生在學習過程中會獲得積分及排名，增添自學中國歷史的趣味及動機。</p>
          <p>此外，「喜閱歷史」專區有不同主題的閱讀篇章；「歷史擂台」專區則提供涵蓋初中課題的評估題目，學生可因應興趣及學習進程，訂立自主學習目標和計劃；而富趣味性和挑戰性的「奪寶奇兵」自學獎勵計劃，能讓同學化身為「奪寶奇兵」，以學習成果換取隱藏的虛擬文物。 </p>
          <p>平台設有「拉闊學習資源區」，為教師及學生推介多元化的學習材料；而「教師專區」則為教師提供中國歷史及文化主題的電子教材及下載學生學習紀錄。</p>
          {/* <p>為配合中國歷史科修訂課程（中一至中三）的推行，並促進學生自主學習，教育局為全港初中學生建立「想・創・中國歷史  —學生自學平台」。平台自學單元配合修訂課程中一至中三級的選教或延伸課題，以動畫、遊戲、多媒體片段等方式，鼓勵學生進行自主學習。</p>
          <p>此外，自學平台設有「歷史擂台」專區，提供涵蓋整個初中課程的評估題目，學生可因應個人興趣和學習進程，訂立自主學習的目標和計劃，完成自學單元及「歷史擂台」題目。</p>
          <p>為進一步鼓勵學生善用自學平台進行自主學習，平台將會推出「奪寶奇兵」全港初中學生自學奬勵計劃，將學歷史、賞文物結合為刺激的學習活動。</p> */}
        </ScrollbarVertical>
      </div>
      {/* <div className="modal-leader-board-kaka-1"></div> */}
    </ModalReel>)
}

export default ModalProfileV4;
