import LeaderboardUser from "../utils/models/LeaderboardUser";

export interface LeaderboardUserEx extends LeaderboardUser {
  sumScore: number,
}

export const extendsLeaderboardUser = (r: Array<LeaderboardUser>): Array<LeaderboardUserEx> => {
  return r.map(x => ({ ...x, sumScore: parseInt(x.sum_score) }))
}

export const LEVELS = [
  { name: '狀元', from: 1401, to: 99999 },
  { name: '榜眼', from: 1201, to: 1400 },
  { name: '探花', from: 1001, to: 1200 },
  { name: '進士', from: 801, to: 1000 },
];

export const filterByLevel = (data: Array<LeaderboardUserEx>, level: 1 | 2 | 3 | 4) => {
  let result = data.filter(x => x.sumScore >= LEVELS[level - 1].from && x.sumScore <= LEVELS[level - 1].to);
  // console.log('filterByLevel', data, result);
  return result;
}

export const getLevelBySumScore = (sumScore: number) => {
  let result = '書生';
  LEVELS.reverse().forEach(level => {
    if (sumScore >= level.from) {
      result = level.name;
    }
  })
  return result;
}
