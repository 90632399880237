import React, { memo, useEffect, useState } from 'react';
import Leaderboard from '../../pages/leaderboard';
import Settings from '../../pages/settings/main';
import { getHeader, getNews, putUserSettings } from '../../utils/api';
import ModelHeader from '../../utils/models/joined/Header';
import News from '../../utils/models/News';
import { useAppTemplateContext } from '../../utils/providers/AppTemplateProvider';
import Modal from '../ui/Modal';
import './Header.scss';
import HeaderV4 from './HeaderV4';
import ModalNews from './modals/News';
import PlatformIntro from './modals/PlatformIntro';
import Profile from './modals/Profile';

/*
interface MainBarSelectorProps {
  handleClick: (n: number) => void,
}
function MainBarSelector(props: MainBarSelectorProps) {
  return (
    <>
      <img style={{ position: 'absolute', top: '100%', left: 0, right: '-8%' }} src="/assets/header/mainbar-scrolldown-01.svg" alt="" />
      <img onClick={() => props.handleClick(1)} style={{ position: 'absolute', top: '110%', left: '12%', width: '80%', zIndex: 2 }} src="/assets/header/mainbar-icon-01.svg" alt="" />
      <img onClick={() => props.handleClick(2)} style={{ position: 'absolute', top: '200%', left: '12%', width: '80%', zIndex: 2 }} src="/assets/header/mainbar-icon-02.svg" alt="" />
      <img onClick={() => props.handleClick(3)} style={{ position: 'absolute', top: '290%', left: '12%', width: '80%', zIndex: 2 }} src="/assets/header/mainbar-icon-03.svg" alt="" />
      <img onClick={() => props.handleClick(4)} style={{ position: 'absolute', top: '380%', left: '12%', width: '80%', zIndex: 2 }} src="/assets/header/mainbar-icon-04.svg" alt="" />
      <img onClick={() => props.handleClick(5)} style={{ position: 'absolute', top: '465%', left: '12%', width: '80%', zIndex: 2 }} src="/assets/header/mainbar-icon-05.svg" alt="" />
      <img onClick={() => props.handleClick(6)} style={{ position: 'absolute', top: '550%', left: '12%', width: '80%', zIndex: 2 }} src="/assets/header/mainbar-icon-06.svg" alt="" />
    </>
  );
}
*/

function MainBarButton(props: MainBarProps) {
  const [showSelector, setShowSelector] = useState(false);
  // const divRef = useRef<HTMLDivElement>(null);
  const [iconIndex, setIconIndex] = useState(0 === props.character ? 17 : props.character);

  const handleClick = (n: number) => {
    putUserSettings('character', n).then(() => { });
    setIconIndex(n);
  }

  return (
    <>
      <div className="student-bar-profile">
        <button className="student-bar-profile-my-profile" onClick={() => setShowSelector(o => !o)} title="我的戶口">
          <img src={`/assets/profile/account-large-icon/account-large-icon${iconIndex}.svg`} tabIndex={1} alt="我的戶口" />
        </button>
        {/* </button> */}
        {/* <div ref={divRef} onClick={() => setShowSelector(o => !o)}> */}
        {/*
      {showSelector ? <img style={{ position: 'absolute', zIndex: 1 }} src={`/assets/header/mainbar-scrolldown-02.svg`} alt="" /> : null}
*/}
        {/* <img style={{ position: 'absolute', zIndex: 1 }} src={`/assets/header/mainbar-scrolldown-02.svg`} alt="" />
          <img style={{ position: 'absolute', zIndex: 2, left: '12%', width: '80%', top: '4%' }} src={`/assets/header/mainbar-icon-0${iconIndex}.svg`} alt="" /> */}
        {/*
      {showSelector ? <MainBarSelector handleClick={handleClick}></MainBarSelector> : null}
*/}
        {/* </div> */}
      </div>
      {showSelector ? <Profile handleClick={handleClick} onClose={() => {
        setShowSelector(false);
      }}></Profile> : null}
    </>
  )
}

interface MainBarProps {
  character: number,
  role: 'Power User' | 'Student' | 'Parent' | 'Teacher' | 'Staff' | 'Other';
}

const ROLETEXT = {
  'Power User': '特級用戶', 'Student': '學生戶口', 'Parent': '家長戶口', 'Teacher': '老師戶口', 'Staff': '職員戶口', 'Other': '其他戶口',
}

function MainBar(props: MainBarProps) {
  // const [text,setText] = useState()
  return (
    <div className="student-bar-main-bar" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/header/mainbar.svg)` }}>
      {/* <img src="/assets/header/mainbar.svg" alt="" /> */}
      <MainBarButton {...props}></MainBarButton>
      {/* <div style={{ position: 'absolute', width: '175px', height: 45, top: 14, left: 133, backgroundColor: '#f3ca64', color: 'white', fontSize: 40, lineHeight: '55px', textShadow: '-1px 1px 0 #F00, 1px 1px 0 #F00, 1px -1px 0 #F00, -1px -1px 0 #F00'}}>{ROLETEXT[props.role]}</div> */}
      <div className="student-bar-type">
        {/* <svg viewBox="0 0 175 45" width="175px" height="45px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  x="0px" y="0px" xmlSpace="preserve" preserveAspectRatio="xMidYMid meet">
          <text x="5" y="40" className="header-svg-bg">{ROLETEXT[props.role]}</text>
          <text x="5" y="40" className="header-svg">{ROLETEXT[props.role]}</text>
        </svg> */}

        <div className={`student-bar-type-text`} title={ROLETEXT[props.role]}>
          <div className="student-bar-type-text-bg">{ROLETEXT[props.role]}</div>
          <div className="student-bar-type-text-text">{ROLETEXT[props.role]}</div>
        </div>
      </div>
    </div>
  )
}

function Header() {
  const { version } = useAppTemplateContext();

  if ("AppV4" === version) {
    return <HeaderV4 />;
  }

  return (
    <header className="v1">
      <div className="student-bar">
        <HeaderUserInfo />
        <HeaderNews />
      </div>
      <div className="buttons">
        <ModalButtonPlatformIntro />
        <ModalButtonAward />
        <ModalButtonSetting />
      </div>
    </header>
  );
}

const HeaderUserInfo = memo(function() {
  const [userInfo, setUserInfo] = useState<ModelHeader | null>(null);

  useEffect(() => {
    getHeader().then(setUserInfo);
  }, []);

  if (null === userInfo) {
    return <></>;
  }

  return (
    <>
      <MainBar character={userInfo.userSettings.character} role={userInfo.role}></MainBar>
      {/* <StudentBarSvg></StudentBarSvg> */}
      {/* <img src="/assets/svg_icon/studentbar.svg" alt=""/> */}
      <span tabIndex={3} title={`班級: ${userInfo.classNo}`} className="class">班級: {userInfo.classNo}</span>
      <span tabIndex={4} title={`${userInfo._.viewUserTotalStars} 星`} className="star">
        <img src="/assets/island/course1-scorebar-3-01-01.svg" alt="星" />
        {userInfo._.viewUserTotalStars}
      </span>
    </>
  );
});

const HeaderNews = memo(function() {
  const [modalNew, setModalNew] = useState<News | null>(null);
  const [news, setNews] = useState<Array<News>>([]);

  const modalShow = (newsItem: News) => {
    setModalNew(newsItem);
  }

  useEffect(() => {
    if (news.length === 0) {
      getNews().then(n => {
        setNews([...n, ...n].slice(0, 2));
      });
    }
  }, [news.length]);

  return (
    <div className="news" style={({ '--line': news.reduce((p, news) => p + news.name.length >= 19 ? 2 : 1, 0) * 2 } as React.CSSProperties)}>
      {[0, 1].map((i) =>
        <div className={`news-marquee news-marquee-${i}`} key={i}>
          {news.map((newsItem, index) =>
            <div key={`${newsItem.id}-${index}`} className={`news-${1 + index}`} onClick={() => modalShow(newsItem)}>
              {0 === index
                ? <span>{newsItem.name}</span>
                : <span>{newsItem.name}</span>
              }
            </div>
          )}
        </div>
      )}
      {modalNew ? <ModalNews news={modalNew} onClose={() => setModalNew(null)} /> : null}
    </div>
  );
})
const ModalButtonPlatformIntro = memo(function ModalButtonPlatformIntroInner() {
  const [modalAboutUs, setModalAboutUs] = useState(false);
  return (
    <>
      <button className="aspect-box" onClick={() => setModalAboutUs(true)} title="關於平台">
        <img src="/assets/svg_icon/questionbutton.svg" alt="關於平台" />
      </button>
      {modalAboutUs ? <PlatformIntro onClose={() => setModalAboutUs(false)} /> : null}
    </>
  )
});
const ModalButtonAward = memo(function ModalButtonAwardInner() {
  const [modalAward, setModalAward] = useState(false);
  return (
    <>
      <button className="aspect-box" onClick={() => setModalAward(true)} title="排行榜">
        <img src="/assets/svg_icon/awardbutton.svg" alt="排行榜" />
      </button>
      {modalAward ? <Modal><Leaderboard onClose={() => setModalAward(false)} /></Modal> : null}
    </>
  )
});
const ModalButtonSetting = memo(function ModalButtonSettingInner() {
  const [modalSetting, setModalSetting] = useState(false);
  return (
    <>
      <button className="aspect-box" onClick={() => setModalSetting(true)} title="設定">
        <img src="/assets/svg_icon/settingbutton.svg" alt="設定" />
      </button>
      {modalSetting ? <Modal><Settings onClose={() => setModalSetting(false)} /></Modal> : null}
    </>
  )
});

export default memo(Header);
