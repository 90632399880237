import { CSSProperties, memo, ReactNode } from 'react';
import "./ScrollbarVertical.scss";
// import useWindowSize from '../../utils/hooks/useWindowSize';

interface ScrollbarVerticalProps {
  children: ReactNode,
  className?: string,
  bg?: {
    width?: number,
    height?: number,
    src?: string,
    style?: CSSProperties,
  },
  track?: {
    width?: number,
    height?: number,
    src?: string,
    ratio?: number,
    style?: CSSProperties,
  }
}

function ScrollbarVertical(props: ScrollbarVerticalProps) {
  return (
    <div className={`scrollbar-vertical ${props.className}`} style={{ } as any}>
      {props.children}
    </div>
  )
}

export default memo(ScrollbarVertical);
