import React, { CSSProperties, useEffect, useState } from 'react';

export type ForceMode = null | 'src1' | 'src2' | 'src3';

interface SvgButtonToggleProps {
  children: React.ReactNode;
  className?: string,
  style?: CSSProperties,
  selected: boolean,
  forceMode?: ForceMode;
  onClick: () => void,
  prefix: string,
  src1: string,
  src2: string,
  src3?: string,
  // alt: string
}
function SvgButtonToggle(props: SvgButtonToggleProps) {
  const [src, setSrc] = useState(props.prefix + (props.selected ? props.src3 : props.src1));

  useEffect(() => {
    if (props.forceMode) {
      setSrc(props.prefix + props[props.forceMode]);
    } else {
      setSrc(props.prefix + (props.selected ? props.src3 : props.src1));
    }
  }, [props, props.forceMode, props.selected, props.prefix, props.src3, props.src1]);

  return (
    <button className={props.className} style={{ ...props.style, backgroundImage: `url(${src})` }}
      onMouseEnter={() => {
        if (props.forceMode) {

        } else {
          if (!props.selected) {
            setSrc(props.prefix + props.src2)
          }
        }
      }}
      onMouseLeave={() => {
        if (props.forceMode) {

        } else {
          if (props.selected) {
            setSrc(props.prefix + props.src3)
          } else {
            setSrc(props.prefix + props.src1)
          }
        }
      }}
      onClick={() => {
        if (props.forceMode) {

        } else {
          props.onClick();
        }
      }}
    >
      {props.children}
    </button>
  )
}

export default (SvgButtonToggle);
