import { memo, ReactNode } from 'react';
// import useWindowSize from '../../utils/hooks/useWindowSize';

interface ArenaChooserScrollbarVerticalProps {
  children: ReactNode
}

function ArenaChooserScrollbarVertical(props: ArenaChooserScrollbarVerticalProps) {

  return (
    <div style={{ overflowY: 'auto', height: '100%' }}>
      {props.children}
    </div>
  )
}

export default memo(ArenaChooserScrollbarVertical);
