import Modal from '../../components/ui/Modal';
import SvgButton from '../../components/ui/SvgButton';
import PageArenaTitle from './arena-title';

interface PageArenaScoreProps {
  index: string | undefined,
  totalCorrectAnswers: number,
  questionTotal: number,
  // score: number,
  onClick: () => void,
}

export default function PageArenaScore(props: PageArenaScoreProps) {
  console.log(props.totalCorrectAnswers);

  const value = {
    'ans1': `${props.totalCorrectAnswers}`,
    'ans': `${props.totalCorrectAnswers}/${props.questionTotal}`,
  };

  return (
    <Modal>
      <div className={`page-island-qna-score page-island-arena-score page-arena-${props.index} dialog`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/arena/battle-score-popup-b.svg)` }}>
        {/* <Link className="dialog-btn-close" to={`/arena`}><SvgButton prefix="/assets/settings/" src1="setting-button-close.svg" src2="setting-button-close-select.svg" alt="關閉"></SvgButton></Link> */}
        <div className="dialog-content">
          <div className="page-island-qna-score-content">
            <PageArenaTitle index={props.index} />

            <div className="ans1">{value.ans1}</div>
            <div className="ans">{value.ans}</div>
          </div>
          <button onClick={props.onClick}>
            <SvgButton prefix="/assets/arena/" src1="battle-button-enter.svg" src2="battle-button-enter-select.svg" alt="確定" />
          </button>
        </div>
      </div>
    </Modal>
  )
}
